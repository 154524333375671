<template>
  <v-container>
    <v-row class="px-0 py-0">
      <modalcreatebroker
        :requestBroker="null"
        v-if="$store.state.modalCreateBrokerVar"
        @updatePage="getData()"
      ></modalcreatebroker>
      <editbroker
        :broker="brokerEdit"
        v-if="$store.state.modalEditBroker"
        @updatePage="getData()"
      ></editbroker>
      <v-col cols="12">
        <h1 class="primary--text">Admin Brokers</h1>
        <span
          >This page allows create or update brokers information</span>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn color="primary" elevation="6" @click="openModalAdd()"
          >New broker <v-icon>mdi-plus</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12" md="4"></v-col>
      <v-col cols="12" md="4">
        <v-text-field
          rounded
          label="Search..."
          filled
          v-model="search"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" class="rounded-lg">
          <v-card-text>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="data"
              :items-per-page="100"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td class="">
                    <strong class="primary--text">
                      {{ item.name }}
                    </strong>
                  </td>
                  <td class="">
                    <strong>
                      {{ item.mc_number }}
                    </strong>
                  </td>
                  <td class="">
                    <strong>
                      {{ item.usdot_number }}
                    </strong>
                  </td>
                  <td>
                    <span v-if="item.credit_report_status == 1">
                      <v-chip class="ma-2" label color="success">
                        Approved
                      </v-chip>
                    </span>
                    <span v-else>
                      <v-chip class="ma-2" label color="error">
                        Not approved
                      </v-chip>
                    </span>
                  </td>
                  <td class="">
                    <v-btn
                      fab
                      small
                      color="primary"
                      @click="openModalEdit(item)"
                    >
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                  <!-- <td>
                    <v-switch @change="changeStatus(item)" color="success" inset v-model="item.status"></v-switch>
                  </td> -->
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import modalcreatebroker from "../../components/modals/consult-brokers/createBroker.vue";
import editbroker from "../../components/modals/consult-brokers/editBroker.vue";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    search: "",
    data: [],
    brokerEdit: [],
    headers: [
      {
        text: "Name",
        value: "name",
        align: "start",
      },
      {
        text: "Mc number",
        value: "mc_number",
        align: "start",
      },
      {
        text: "Usdot number",
        value: "usdot_number",
        align: "start",
      },
      {
        text: "Credit report",
        value: "credit_report_status",
        align: "start",
      },
      {
        text: "",
        align: "start",
      },
    ],
  }),
  components: { modalcreatebroker, editbroker },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getData: function () {
      this.showLoading();
      const BASE_URL = `${this.ip}get-brokers`;
      axios
        .get(BASE_URL)
        .then((res) => {
          this.hideLoading();
          this.data = res.data.data;
        })
        .catch((err) => {
          this.hideLoading();
          console.log(err);
        });
    },
    changeStatus: function (broker) {
      this.showLoading();
      const BASE_URL = `${this.ip}change-status-broker`;
      axios
        .post(BASE_URL, broker)
        .then((res) => {
          this.hideLoading();
          const optionsAler = {
            message: res.data.message,
            action: "close",
            path: `/`,
          };
          this.openAlert(optionsAler);
          this.$emit("updatePage");
          this.getData();
        })
        .catch((err) => {
          this.hideLoading();
          console.log(err);
        });
      // console.log(broker)
    },
    openModalAdd: function () {
      this.$store.state.modalCreateBrokerVar = true;
    },
    openModalEdit: function (broker) {
      this.brokerEdit = broker;
      this.$store.state.modalEditBroker = true;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
