<template>
  <v-app style="background-color: #f6f6f6">
    <header>
      <router-view name="header" />
    </header>
    <v-main>
      <router-view name="content" />
      <v-overlay :value="$store.state.overlay">
        <v-progress-circular indeterminate size="120">
          Loading...
        </v-progress-circular>
      </v-overlay>
      <alert />
    </v-main>
    <footer>
      <router-view name="footer" />

        <input type="text" v-mask="'###-***-####'" v-model="myInputModel">
        {{myInputModel}}

        <br> <br> <br>

         
    </footer>
  </v-app>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask);

import alert from "../src/components/modals/alertModal.vue";
 
export default {
  name: "App",

  data: () => ({
    myInputModel: ''
  }),
  components: { alert }
};
</script>
<style>
.v-btn {
  /* border-radius: 0px !important; */
  text-transform: none !important;
}
</style>
