<template>
  <v-dialog v-model="dialog" width="500px" persistent>
    <v-card class="rounded-lg" v-show="modal == true">
      <v-toolbar dark color="primary" elevation="0">
        <h2 class="">
          {{ action == 1 ? "Accept request" : "Decline request" }}
        </h2>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-card-subtitle>
        This action requires completing an information
      </v-card-subtitle> -->
      <br>
      <v-card-text>
        <v-form v-model="form">
          <v-text-field
            class="input"
            label="Broker Mc number"
            :value="item.broker_mcnumber"
            outlined
            disabled
          />
          <v-text-field
            class="input"
            label="Broker name"
            v-model="item.broker_name"
            outlined
          />
          <v-text-field
            class="input"
            label="Broker usdot number"
            outlined
            v-model="item.broker_usdot_number"
          />
          <v-text-field
            label="Broker fp code"
            outlined
            v-model="item.broker_fp_code"
          />
          <v-btn
            block
            color="primary"
            v-if="action == 1"
            elevation="0"
            @click="answerRequest(1)"
            x-large
            >Accept request</v-btn
          >
          <v-btn
            block
            color="error"
            v-if="action == 2"
            elevation="0"
            @click="answerRequest(2)"
            x-large
            >Decline request</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  props: ["dialog", "action", "item"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    form: false,
    modal: true,
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    answerRequest: function (action) {
      this.modal = false;
      this.showLoading();
      this.item.action = action;
      const BASE_URL = `${this.ip}answer-request`;
      axios
        .post(BASE_URL, this.item)
        .then((res) => {
          if (res.data.status == true) {
            this.updateData();
            this.hideLoading();
            this.closeDialog();
            const optionsAler = {
              message: "Request answered",
              submessage: `User ${this.item.request_user} request was answered`,
              action: "close",
              path: "/",
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          this.modal = true;
          console.log(err);
        });
    },
    closeDialog: function () {
      this.$emit("closeDialogActions");
    },
    updateData: function () {
      this.$emit("updateDataF");
    },
  },
};
</script>
<style scoped>
.input {
  margin-bottom: -17px;
}
</style>
