<template>
  <v-container>
    <modalcreatebroker
      :requestBroker="itemSelected"
      v-if="$store.state.modalCreateBrokerVar"
      @updatePage="getData()"
    ></modalcreatebroker>
    <v-row class="py-0 px-0">
      <v-col cols="12">
        <h1 class="primary--text">Audit Log</h1>
        <span>Search broker activity log</span>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" class="rounded-lg">
          <v-card-text>
            <v-form v-model="formData.form">
              <v-row class="mt-4">
                <v-col cols="5">
                  <v-text-field
                    type="date"
                    v-model="formData.initialDate"
                    filled
                    :rules="[rules.req]"
                    rounded
                    label="Initial date"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    type="date"
                    v-model="formData.finalDate"
                    filled
                    rounded
                    :rules="[rules.req]"
                    label="Final date"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <center>
                    <v-btn fab color="primary" @click="getData()">
                      <!--  :disabled="!formData.form" -->
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </center>
                </v-col>
              </v-row>
            </v-form>
            <v-row dense>
              <v-col cols="3">
                <v-text-field
                  dense
                  filled
                  rounded
                  v-model="search"
                  label="Search..."
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-data-table :search="search" :headers="headers" :items="data">
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="py-3 my-4">
                        <span>
                          <v-icon>{{ item.icon }}</v-icon>
                        </span>
                      </td>
                      <td class="">
                        <strong class="primary--text">
                          {{ item.typeLog }}
                        </strong>
                      </td>
                      <td class="">
                        <span>
                          {{ item.emailRequest }}
                        </span>
                      </td>
                      <td>
                        <span>{{ item.phoneRequest }}</span>
                      </td>
                      <td class="">
                        <span>
                          {{ item.brokerMcNumber }}
                        </span>
                      </td>
                      <td class="">
                        {{ item.dateLog.substr(0, 10) }}
                      </td>
                      <td class="">
                        <v-chip
                          class="ma-2"
                          :color="item.color"
                          label
                          small
                          v-if="item.color != null"
                          text-color="white"
                        >
                          <v-icon left> mdi-label </v-icon>
                          {{ item.statusName }}
                        </v-chip>
                      </td>
                      <td class="">
                        <v-btn
                          color="primary"
                          v-if="item.created_broker == 1"
                          small
                          elevation="0"
                          @click="createBroker(item)"
                          rounded
                          >Create broker</v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

import modalcreatebroker from "../../components/modals/consult-brokers/createBroker.vue";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    search: "",
    data: [],
    formData: {
      form: "",
      initialDate: "",
      finalDate: "",
    },
    rules: {
      req: (value) => !!value || "required.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      max: (v) => (v && v.length <= 30) || "Max 30 characters",
    },
    itemSelected: [],
    headers: [
      {
        text: "",
        align: "start",
        value: "",
      },
      {
        text: "Request Type",
        value: "typeLog",
        align: "start",
      },
      {
        text: "Customer Email",
        value: "emailRequest",
        align: "start",
      },
      {
        text: "Customer Phone",
        value: "phoneRequest",
        align: "start",
      },
      {
        text: "MC/USDOT number",
        value: "brokerMcNumber",
        align: "start",
      },
      {
        text: "Request Date",
        value: "dateLog",
        align: "start",
      },
      {
        text: "Status",
        value: "statusName",
        align: "start",
      },
      {
        text: "Actions",
        align: "start",
      },
    ],
  }),
  components: { modalcreatebroker },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getData: function () {
      this.showLoading();
      const BASE_URL = `${this.ip}log-brokers/${this.formData.initialDate}/${this.formData.finalDate}`;
      axios
        .get(BASE_URL)
        .then((res) => {
          const data = res.data.data;
          let dataFormat = [];
          data.forEach((element) => {
            const consultData = JSON.parse(element.notes);
            dataFormat.push({
              icon:
                element.id_log == "CONSULT_BROKER"
                  ? "mdi-magnify"
                  : "mdi-file-document",
              brokerMcNumber:
                element.id_log == "CONSULT_BROKER"
                  ? consultData.search
                  : consultData.broker_mcnumber,
              brokerName:
                element.id_log == "CONSULT_BROKER"
                  ? null
                  : consultData.broker_name,
              emailRequest: consultData.emailRequest,
              phoneRequest: consultData.phoneRequest,
              typeLog:
                element.id_log == "CONSULT_BROKER"
                  ? "Broker Search"
                  : "Broker Request",
              dateLog: element.created_at,
              status: element.status,
              statusName: element.name,
              color: element.color,
              created_broker: element.ind_create_broker,
              idRequest: element.rowid,
            });
          });
          this.data = dataFormat;
          this.hideLoading();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createBroker: function (broker) {
      this.itemSelected = broker;
      this.$store.state.modalCreateBrokerVar = true;
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
