<template>
  <v-container>
    <v-row class="py-3 px-3">
      
      <modalrequest
        :rules="rules"
        :broker_number="formSearch.search"
        v-if="$store.state.requestConstultBrokerModal == true"
      ></modalrequest>
      <modalrequestinformation
        v-if="$store.state.requestInformationModal"
      ></modalrequestinformation>
      <v-col cols="12">
        <span class="close-search" @click="push('/')"> <!--  <v-icon>mdi-chevron-left</v-icon> --> 🚫 Close</span>
        <br> <br>
        <h1> <span class="black--text">Search brokers</span></h1>  
        <h3 class="primary--text">📧 {{ formSearch.emailSearch }}</h3>
        <h3 class="primary--text">📱 {{ formSearch.phoneSearch }}</h3>
        <br>
        <div >
                  <v-alert
                    elevation="0"
                    text
                    color="info" 
                    border="left"
                    prominent
                  >
                    <v-row align="center" no-gutters dense>
                      <v-col class="grow">
                        <p>Enter <b>MC number</b> or <b>USDOT number</b><br>of the broker to search</p>
                      </v-col>
                    </v-row>
                  </v-alert>
                </div>
      </v-col>
      <v-col cols="12">
        <v-card elevation="0" color="white" class="rounded-lg">
          <v-card-text>
            <v-row justify="center" dense class="pt-2">
              <v-col cols="12">
                <v-form v-model="formSearch.form">
                  <v-row>
                    <v-col cols="12" md="9">
                      <v-text-field
                        class="input"
                        filled type="number"
                        label="MC number, USDOT number ..."
                        v-model="formSearch.search"
                        :rules="[rules.req]"     
                        x-large
                        rounded                      
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-btn
                        x-large
                        pt-0
                        block
                        elevation="0"                        
                        color="primary"
                        @click="searchBroker()"
                        :disabled="!formSearch.form"
                      >
                        <strong>Search<v-icon>mdi-magnify</v-icon></strong>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col cols="12" v-if="section == 1">
                <!-- <v-data-table
                  hide-default-footer
                  class="elevation-1"
                  :headers="headers"
                  :items="brokers"
                  :items-per-page="100"
                  v-if="brokers != ''"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        <span>{{ item.mc_number }}</span>
                      </td>
                      <td>
                        <span>{{ item.usdot_number }}</span>
                      </td>                      
                      <td>
                        <strong>{{ item.name }}</strong>
                      </td>

                      <td>
                        <v-chip
                          label
                          color="success"
                          v-if="item.credit_report_status == 1"
                        >
                          Approved
                        </v-chip>
                        <v-chip
                          small
                          color="error"
                          v-if="item.credit_report_status == 0"
                        >
                          Not approved
                        </v-chip>
                      </td>
                    </tr>
                  </template>
                </v-data-table> -->
                <v-row>
                  <v-col cols="12" v-for="broker in brokers" :key="broker">
                    <v-card class="rounded-lg">
                      <v-card-text>
                        <v-row>
                            <v-col cols="12" md="3">
                              <strong>MC NUMBER</strong>
                              <p class="grey--text">{{broker.mc_number}}</p>
                            </v-col>
                            <v-col cols="12" md="3">
                              <strong>USDOT NUMBER</strong>
                              <p class="grey--text">{{broker.usdot_number}}</p>
                            </v-col>
                            <v-col cols="12" md="3">
                              <strong>NAME</strong>
                              <p class="grey--text">{{broker.name}}</p>
                            </v-col>
                            <v-col cols="12" md="3">
                              <strong>REPORT</strong> <br>
                              <v-chip
                          label
                          color="success"
                          v-if="broker.credit_report_status == 1"
                        >
                          Approved
                        </v-chip>
                        <v-chip
                          small
                          color="error"
                          v-if="broker.credit_report_status == 0"
                        >
                          Not approved
                        </v-chip>
                            </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-else>
                <v-alert
                  elevation="0"
                  text
                  color="error"
                  dense
                  class="rounded-lg"
                  icon="mdi-database"
                  prominent
                >
                  <v-row align="center" no-gutters dense>
                    <v-col class="grow">
                      <h3>Broker not found</h3>
                      <p>
                        We have not found this broker in our database 
                      </p>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="shrink">
                      <v-btn
                        color="error"
                        elevation="0"
                        x-large
                        class="rounded-lg"
                        @click="$store.state.requestConstultBrokerModal = true"
                      >
                        <strong
                          >request approval</strong
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import modalrequest from "../../components/modals/consult-brokers/requestConsult.vue";
import modalrequestinformation from "../../components/modals/consult-brokers/requireInformation.vue";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    formSearch: {
      form: false,
      type_search: "mc_number",
      search: "",
      phoneSearch: "",
      emailSearch: "",
    },
    itemsSearch: [
      { text: "Mc number", value: "mc_number" },
      { text: "Name", value: "name" },
    ],
    section: 1,
    brokers: [],
    rules: {
      req: (value) => !!value || "required.",
    },

    headers: [

      { text: "MC #", value: "mc_number" },
      { text: "USDOT #", value: "usdot_number" },
      {
        text: "Broker name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Status", value: "status" },
    ],
  }),
  components: {
    modalrequest,
    modalrequestinformation,
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    searchBroker: function () {
      this.formSearch.emailSearch = localStorage.getItem("emailRequest");
      this.formSearch.phoneSearch = localStorage.getItem("phoneRequest");
      this.showLoading();
      const BASE_URL = `${this.ip}search-brokers`;
      axios
        .post(BASE_URL, this.formSearch)
        .then((res) => {
          this.hideLoading();
          if (res.data.data.length > 0) {
            this.section = 1;
            this.brokers = res.data.data;
          } else {
            this.section = 2;
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    preparePage: function () {
      const emailRequest = localStorage.getItem("emailRequest");
      const phoneRequest = localStorage.getItem("phoneRequest");
      console.log(emailRequest, "emailRequest");

      if (emailRequest == null || phoneRequest == null) {
        this.$store.state.requestInformationModal = true;
      }

      this.formSearch.phoneSearch = phoneRequest;
      this.formSearch.emailSearch = emailRequest;
    },
    push:function(path){
      location.reload();
      localStorage.removeItem('emailRequest');
      localStorage.removeItem("phoneRequest");
    }
  },
  mounted() {
    this.preparePage();
  },
};
</script>
<style scoped>
.close-search{
  cursor: pointer !important;
}

.input {
  margin-bottom: -30px;
  border-radius: 5px !important;
}
</style>
