import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        overlay: false,
        alert: {
            alert: false,
            icon: 'mdi-bell',
            colorIcon: 'info',
            colorAvatar: 'info lighten-5',
            message: '',
            submessage: '',
            action: 'close',
            path: '/',
        },

        recoverPassword: false,
        requestConstultBrokerModal: false,
        requestInformationModal: false,
        modalCreateBrokerVar: false,
        modalEditBroker: false,
    },
    mutations: {
        showLoading(state) {
            state.overlay = true;
        },
        hideLoading(state) {
            state.overlay = false;
        },

        openAlert(state, options) {
            state.alert.alert = true;
            state.alert.message = options.message;
            state.alert.submessage = options.submessage;
            state.alert.action = options.action;
            state.alert.path = options.path;
        },

        closeAlert(state) {
            state.alert.alert = false;
            state.alert.message = '';
            state.alert.submessage = '';
            state.alert.action = '';
        }
    },
    actions: {},
    modules: {}
})