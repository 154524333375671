<template>
  <v-container>
    <v-stepper
      v-model="validationRegister"
      vertical
      class="elevation-0 rounded-lg"
    >
      <v-stepper-step
        :complete="stepCurrent > 1"
        step="1"
        @click="seeStep(1)"
      >
        <h2 class="primary--text">Business Profile <v-icon v-if="stepCurrent>1" small color="primary">mdi-eye</v-icon></h2>
        <small class="grey--text"
          >Please complete the business information.</small
        >
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="white" class="mb-12" outlined>
          <businessform
            :rules="rules"
            v-if="validationRegister == 1"
            :formDataBusiness="formDataBusiness"
            @registerDataBusiness="registerBusinessContact()"
          />
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="stepCurrent > 2"
        step="2"
        @click="seeStep(2)"
      >
        <h2 class="primary--text">Owner(s) principal(s) profile <v-icon v-if="stepCurrent>2" small color="primary">mdi-eye</v-icon></h2>
        <small>Please complete owner information</small>
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card color="white" class="mb-12" elevation="0">
          <businesscontactform
            :rules="rules"
            :formDataContact="formDataContact"
            @registerOwnerContact="registerBusinessAccountContact()"
          />
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="stepCurrent > 3"
        step="3"
        @click="seeStep(3)"
      >
        <h2 class="primary--text">Upload required documents <v-icon v-if="stepCurrent>3" small color="primary">mdi-eye</v-icon></h2>
        <small></small>
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card
          elevation="0"
          class="mb-12"
          color="white"
          v-if="formDataBusiness.user != '' && validationRegister == 3"
        >
          <formdocuments
            :userId="formDataBusiness.user"
            @nextStep="nextStepF()"
          />
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="stepCurrent > 4"
        step="4"
        @click="seeStep(4)"
      >
        <h2 class="primary--text">Setup your username</h2>
        <small>Enter the username password</small>
      </v-stepper-step>

      <v-stepper-content step="4">
        <v-card elevation="0" class="mb-12">
          <v-card-text>
            <v-form v-model="formDataUser.form">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    outlined
                    :value="formDataUser.email"
                    label="Email"
                    maxlength="80"
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    outlined
                    :value="formDataUser.phone"
                    label="Phone"
                    maxlength="10"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h3 class="primary--text">Create your password</h3>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="formDataUser.password"
                    :append-icon="
                      formDataUser.typePassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="[rules.required, rules.min]"
                    :type="formDataUser.typePassword ? 'text' : 'password'"
                    name="input-password"
                    label="Password"
                    outlined
                    @click:append="
                      formDataUser.typePassword = !formDataUser.typePassword
                    "
                  ></v-text-field>
                  <password
                    v-model="formDataUser.password"
                    :toggle="true"
                    @score="showScore"
                    @feedback="showFeedback"
                    :strength-meter-only="true"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="formDataUser.password_verify"
                    :append-icon="
                      formDataUser.typePasswordVerify
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    :rules="[rules.required, rules.min]"
                    :type="
                      formDataUser.typePasswordVerify ? 'text' : 'password'
                    "
                    name="input-password"
                    label="Verify Password"
                    outlined
                    @click:append="
                      formDataUser.typePasswordVerify =
                        !formDataUser.typePasswordVerify
                    "
                  ></v-text-field>
                  <password
                    v-model="formDataUser.password_verify"
                    :toggle="true"
                    @score="showScore"
                    @feedback="showFeedback"
                    :strength-meter-only="true"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              elevation="6"
              :disabled="
                !formDataUser.form ||
                formDataUser.scorePassword < 3 ||
                formDataUser.password != formDataUser.password_verify
              "
              @click="updateDataAccount()"
            >
              Complete account setup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper>
  </v-container>
</template>
<script>
import axios from "axios";
import Password from "vue-password-strength-meter";
import { mapMutations } from "vuex";

import businessform from "../../components/generals/completeRegistration/businessInformation.vue";
import businesscontactform from "../../components/generals/completeRegistration/businessContact.vue";
import formdocuments from "../../components/generals/completeRegistration/upLoadFiles.vue";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    validationRegister: 1,
    stepCurrent: 0,

    formDataBusiness: {
      form: false,
      token: "",
      name: "",
      dba: "",
      biz_type: "",
      tax_id_ein: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      user: "",
      biz_created_on: "",
      usdnumber: "",
      mcnumber: "",
      numbertrucks: 0,
      equipment_type: "",
      authorty_type: "",

      // CONTACT

      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      telephone: "",
      type_contact: 2,
    },

    formDataContact: {
      isOwner: false,
      id_contact: null,
      form: false,
      user: "",
      firstname: "",
      lastname: "",
      ssn: "",
      email: "",
      mobile: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      ownership_percent: "",

      // owner two

      firstnametwo: "",
      lastnametwo: "",
      ssntwo: "",
      emailtwo: "",
      mobiletwo: "",
      phonetwo: "",
      addresstwo: "",
      citytwo: "",
      statetwo: "",
      zipcodetwo: "",
      countrytwo: "",
      ownership_percenttwo: "",
    },

    formDataUser: {
      form: false,
      password: "",
      password_verify: "",
      typePassword: false,
      typePasswordVerify: false,
      scorePassword: 0,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      userAccount: "",
    },

    rules: {
      req: (value) => !!value || "required.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      max: (v) => (v && v.length <= 30) || "Max 30 characters",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalid",
      // igual: (v) => v != formDataUser.password || 'Las claves deben coincidir',
    },
  }),
  components: { Password, businessform, businesscontactform, formdocuments },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    verifiedUser() {
      this.showLoading();
      const token = this.$route.params.token;
      const BASE_URL = `${this.ip}find-user-complete-register&token=${token}`;
      axios
        .get(BASE_URL)
        .then((res) => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          // console.log(res.data);
          this.hideLoading();
          if (res.data.userId == "Not user") {
            this.$router.push("/not-register-user");
          } else {
            if (res.data.stepForm > 4) {
              this.$router.push("/registration-started");
            } else {
              this.formDataBusiness.user = res.data.userId;
              this.formDataBusiness.token = token;
              this.formDataContact.user = res.data.userId;
              this.formDataUser.userAccount = res.data.userId;
              this.validationRegister = res.data.stepForm;
              this.stepCurrent = res.data.stepForm;

             

              this.formDataUser.email = res.data.userData.username;
              this.formDataUser.phone = res.data.userData.phone;

              

              if (res.data.stepForm == 2) {
                this.formDataBusiness = res.data.biz_account;
                this.formDataBusiness.user = res.data.userId;
                this.formDataBusiness.token = token;
                this.formDataContact.user = res.data.userId;
                this.formDataUser.userAccount = res.data.userId;
                this.formDataBusiness.firstname =
                  res.data.biz_contact.firstname;
                this.formDataBusiness.lastname = res.data.biz_contact.lastname;
                this.formDataBusiness.email = res.data.biz_contact.email;
                this.formDataBusiness.mobile = res.data.biz_contact.mobile;
                this.formDataBusiness.telephone = res.data.biz_contact.phone;
              }

              if (res.data.stepForm == 3) {
                this.formDataBusiness = res.data.biz_account;
                this.formDataBusiness.user = res.data.userId;
                this.formDataBusiness.token = token;
                this.formDataContact.user = res.data.userId;
                this.formDataUser.userAccount = res.data.userId;
                this.formDataBusiness.firstname =
                  res.data.biz_contact.firstname;
                this.formDataBusiness.lastname = res.data.biz_contact.lastname;
                this.formDataBusiness.email = res.data.biz_contact.email;
                this.formDataBusiness.mobile = res.data.biz_contact.mobile;
                this.formDataBusiness.telephone = res.data.biz_contact.phone;
               

                this.formDataContact.firstname = res.data.biz_contact.firstname;
                this.formDataContact.lastName = res.data.biz_contact.lastname;
                this.formDataContact.ssn = res.data.biz_contact.ssn;
                this.formDataContact.email = res.data.biz_contact.email;
                this.formDataContact.mobile = res.data.biz_contact.mobile;
                this.formDataContact.phone = res.data.biz_contact.phone;
                this.formDataContact.address = res.data.biz_contact.address;
                this.formDataContact.city = res.data.biz_contact.city;
                this.formDataContact.state = res.data.biz_contact.state;
                this.formDataContact.zipcode = res.data.biz_contact.zipcode;
                this.formDataContact.ownership_percent =
                  res.data.biz_contact.ownership_percent;

                if (res.data.biz_contact_two) {
                  this.formDataContact.firstnametwo =
                    res.data.biz_contact_two.firstname;
                  this.formDataContact.lastnametwo =
                    res.data.biz_contact_two.lastname;
                  this.formDataContact.ssntwo = res.data.biz_contact_two.ssn;
                  this.formDataContact.emailtwo =
                    res.data.biz_contact_two.email;
                  this.formDataContact.mobiletwo =
                    res.data.biz_contact_two.mobile;
                  this.formDataContact.phonetwo =
                    res.data.biz_contact_two.phone;
                  this.formDataContact.addresstwo =
                    res.data.biz_contact_two.address;
                  this.formDataContact.citytwo = res.data.biz_contact_two.city;
                  this.formDataContact.statetwo =
                    res.data.biz_contact_two.state;
                  this.formDataContact.zipcodetwo =
                    res.data.biz_contact_two.zipcode;
                  this.formDataContact.ownership_percenttwo =
                    res.data.biz_contact_two.ownership_percent;
                }
              }
            }
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    registerBusinessContact: function () {
      this.showLoading();
      const BASE_URL = `${this.ip}register-business-account`;
      axios
        .post(BASE_URL, this.formDataBusiness)
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            this.validationRegister = 2;
            this.stepCurrent = 2;
            this.verifiedUser();
            const optionsAler = {
              message: res.data.message,
              action: "close",
              path: `/`,
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    registerBusinessAccountContact: function () {
      this.showLoading();
      const BASE_URL = `${this.ip}register-business-account-contact`;
      axios
        .post(BASE_URL, this.formDataContact)
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            this.validationRegister = 3;
            this.stepCurrent = 3;
            const optionsAler = {
              message: res.data.message,
              action: "close",
              path: `/`,
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },

    updateDataAccount: function () {
      this.showLoading();
      const BASE_URL = `${this.ip}complete-account-data`;
      axios
        .post(BASE_URL, this.formDataUser)
        .then((res) => {
          this.hideLoading();
          if (res.data.message == "Register Successfully") {
            const optionsAler = {
              message: "Account Setup Completed",
              submessage:
                "You will be receiving an email with the factoring agreement documents for your signature", 
              action: "redirect",
              path: `/registration-started`,
            };
            this.openAlert(optionsAler);
            this.$router.push("/registration-started");
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    seeStep: function (step) {
      if (step <= this.stepCurrent) {
        this.validationRegister = step;
      } else {
        const optionsAler = {
          message: "First you must fill in necessary information",
          action: "close",
          path: `/`,
        };
        this.openAlert(optionsAler);
      }
    },
    nextStepF: function () {
      this.stepCurrent = 4;
      this.validationRegister = 4;
    },
    showFeedback({ suggestions, warning }) {
      // console.log("🙏", suggestions);
      // console.log("⚠", warning);
    },
    showScore(score) {
      this.formDataUser.scorePassword = score;
    },
  },
  mounted() {
    this.verifiedUser();
  },
};
</script>
