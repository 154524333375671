<template>
  <v-row dense>
    <v-col cols="12" md="4">
      <v-text-field
        outlined
        dense
        :disabled="true"
        :value="user.firstName + ' ' + user.lastName"
        label="Full name"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        outlined
        dense
        :disabled="true"
        :value="user.username"
        label="Email"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        outlined
        dense
        :disabled="true"
        maxlength="10"
        :value="user.phone"
        label="Phone"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: ["user"],
  data: () => ({}),
};
</script>
