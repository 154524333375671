<template>
  <v-row class="py-8 px-8">
    <v-col cols="12">
      <h1 class="primary--text">Advance Schedules</h1>
    </v-col>
    <v-col cols="12">
      <modalnewoperation :modal="modalAdd" @closeModal="closeModalAdd()" />
      <v-card elevaiton="0" class="rounded-lg" color="white">
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12">
              <v-btn elevation="0" @click="openModalAdd()" color="primary"
                >New advance schedule</v-btn
              >
            </v-col>
            <!-- <v-col cols="12" class="mt-10">
              <h2 class="primary--text">Your advance schedules</h2>
              <span class="grey--text">List of advances</span>
            </v-col>-->
            <v-col cols="12" class="mt-5">
              <v-data-table
                :headers="headers"
                :items="operations"
                class="elevation-0"
                :items-per-page="100"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      <span>{{ item.advance_type }}</span>
                    </td>
                    <td>
                      <span>{{ item.schedule_no }}</span>
                    </td>
                    <td>
                      <span>{{ item.operation_date }}</span>
                    </td>
                    <td>
                      <v-chip
                        label
                        small
                        @click="push(item.rowid)"
                        class="white--text"
                        :color="item.color"
                        
                      >
                        {{item.name}}
                      </v-chip>
                    
                    </td>
                    <!-- <td>
                      <v-btn color="primary" small icon elevation="0" @click="push(item.rowid)">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-btn>
                    </td> -->
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import modalnewoperation from "../../components/generals/operations/addOperation.vue";
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    modalAdd: false,
    operations: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    headers: [
      { text: "Advance type", value: "advance_type" },
      { text: "Schedule No.", value: "schedule_no" },
      { text: "Advance date", value: "operation_date" },
      { text: "Status", value: "status" }
    ],
  }),
  components: {
    modalnewoperation,
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getOperations: function () {
      this.showLoading();
      const USERSESSION = JSON.parse(localStorage.getItem("USERDATA"));
      const TOKEN = USERSESSION.token;
      const USERNAME = USERSESSION.username;
      const BASE_URL = `${this.ip}operations-by-user&user=${USERNAME}`;
      let config = {
        headers: {
          Authorization: "Bearer " + TOKEN,
        },
      };
      axios
        .get(BASE_URL, config)
        .then((res) => {
          this.hideLoading();
          const data = res.data.data;
          if (data.length > 0) {
            this.operations = data;
          }
        })
        .catch((err) => {
          const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    openModalAdd: function () {
      this.modalAdd = true;
    },
    closeModalAdd: function () {
      this.getOperations();
      this.modalAdd = false;
    },
    push(id){
        this.$router.push(`/detail-operation/${id}`)
    }
  },
  mounted() {
    this.getOperations();
  },
};
</script>
