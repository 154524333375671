<template>
  <v-container>
    <v-row v-if="data != ''">
      <!--<v-col cols="12">
       
        <v-toolbar color="primary" class="white--text"
          >Review Contract</v-toolbar
        > 
      </v-col>-->
      <v-col cols="12">
        <v-tabs
          v-model="tabs"
          align-with-title
          color="white"
          background-color="primary"
          flat
          dark
        >
          <v-tab>Express Sign up</v-tab>
          <v-tab>NOA contract</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item>
            <iframe
              id="inlineFrameExample"
              title="Inline Frame Example"
              style="width: 100%; height: 400px; border: none"
              :src="urlContractExpress"
            />
          </v-tab-item>

          <v-tab-item>
            <iframe
              id="inlineFrameExample"
              title="Inline Frame Example"
              style="width: 100%; height: 400px; border: none"
              :src="urlContract"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>

      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <v-alert
              text
              color="success"
              class="white--text rounded-lg"
              border="left"
              elevation="0"
              icon="mdi-grease-pencil"
              prominent
            >
              <small
                ><small>
                  By signing below I/We certify The information supplied in this
                  Application and all forms and documents submitted to Factor
                  Plus LLC. in connection herewith are true and correct. I/we
                  hereby authorize Factor Plus LLC its affiliates, assigns,
                  agents, bank or financial institutions or subsidiaries to
                  verify the accuracy of the statements made in this application
                  and to determine our creditworthiness by checking our credit
                  reports as Factor Plus LLC deems necessary. I/we grant Plus
                  LLC Inc. the right to procure any and all credit reports
                  pertaining to any part of this application. Further, your
                  signature authorizes Plus LLC. to send information whether
                  commerical, business, or otherwise via facsimile or electronic
                  transmission to the facsimile number or e-mail address I/we
                  have provided herein.</small
                ></small
              >
            </v-alert>
          </v-col>
          <v-col cols="12" md="1" v-if="readonlyContracts==false">
            <v-text-field
              outlined
              v-model="initials"
              label="Initials"
              maxlength="4"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" v-if="readonlyContracts==false">
            <v-text-field
              outlined
              v-model="fullName"
              label="Full name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" v-if="readonlyContracts==false">
            <v-btn
              elevation="0"
              @click="signContract()"
              :disabled="fullName == '' || initials == ''"
              color="primary"
              x-large
              >Sign</v-btn
            >
            <v-btn elevation="0" class="mx-2" color="error" x-large
              >Cancel</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    readonlyContracts: false,
    tabs: 0,
    initials: "",
    fullName: "",
    data: [],
    urlContract: "",
    urlContractExpress: "",
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getDataContract: function () {
      this.showLoading();
      const idUser = this.$route.params.idUser;
      const BASE_URL = `${this.ip}get-data-contract&idUser=${idUser}`;
      axios
        .get(BASE_URL)
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            this.data = res.data.data;
            this.urlContract = res.data.contract;
            this.urlContractExpress = res.data.contractExpress;
          } else {
            const optionsAler = {
              message: "We have not found contracts available to sign",
              submessage:
                "Remember that you can only sign the contract once, check your email if you have already signed, we will send a copy",
              action: "redirect",
              path: `/`,
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    signContract: function () {
      this.showLoading();
      this.data.fullName = this.fullName;
      this.data.initials = this.initials;

      const BASE_URL = `${this.ip}sign-contract`;
      axios
        .post(BASE_URL, this.data)
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            const optionsAler = {
              message: res.data.message,
              submessage: "A copy of the factoring documents were sent to your email.",
              action: "redirect",
              path: `/`,
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          this.hideLoading();
          console.log(err);
        });
    },
  },
  mounted() {
    this.getDataContract();
    if(this.$route.params.readonly){
      this.readonlyContracts = this.$route.params.readonly == 1 ? true : false;
    }
  },
};
</script>
