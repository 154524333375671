<template>
  <v-list-item class="">
    <v-list-item-avatar size="80">
      <v-avatar :color="colorAvatarIcon">
        <v-icon :color="colorIcon" size="30">{{ icon }}</v-icon>
      </v-avatar>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        <h2 :class="classTextTitle">{{ title }}</h2>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
    props: ['colorIcon', 'colorAvatarIcon', 'classTextTitle', 'title', 'subtitle', 'icon'],
    mounted(){
        if(this.colorIcon == undefined){
            this.colorIcon = 'primary';
        }
    }
}
</script>
