<template>
  <v-dialog v-model="dialog" v-show="showCard" persistent max-width="550">
    <v-card class="rounded-lg" v-show="showCard == true">
      <v-toolbar dark color="primary" elevation="0">
        <h2 class="">Register new user</h2>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="closeModalAddUser()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <br />
      <v-card-text>
        <v-form>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                class="input"
                label="Email"
                :rules="[rules.req, rules.email]"
                v-model="dataForm.email"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                class="input"
                label="Phone"
                :rules="[rules.req]"
                v-model="dataForm.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <!-- <input type="checkbox" v-model="dataForm.completeAdmin">
              <label for="" class="ml-2">Registration will be completed from the administrator</label> -->
              <v-card
                v-if="typeAccountUser == 'Client'"
                hover
                outlined
                @click="registerUser(true)"
                class="rounded-lg pt-0 pb-0 mb-0 mt-0"
              >
                <v-card-text class="pt-0 pb-0 mb-0 mt-0">
                  <v-list class="pt-0 pb-0 mb-0 mt-0">
                    <v-list-item>
                      <v-list-item-avatar tile>
                        <v-icon size="40" color="primary"
                          >mdi-account-multiple-plus</v-icon
                        >
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="primary--text"
                            >Complete registration from the
                            administrator</strong
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <small class="grey--text"
                            >With this option you will be redirected to complete
                            the <br />
                            customer registration</small
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
              <br />
              <v-card
                hover
                @click="registerUser()"
                outlined
                class="rounded-lg pt-0 pb-0 mb-0 mt-0"
              >
                <v-card-text class="pt-0 pb-0 mb-0 mt-0">
                  <v-list class="pt-0 pb-0 mb-0 mt-0">
                    <v-list-item>
                      <v-list-item-avatar tile>
                        <v-icon size="40" color="success">mdi-gmail</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <strong class="success--text"
                            >Send registration link to user</strong
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <small class="grey--text"
                            >With this option the link to complete the
                            registration will <br />
                            be sent to the user</small
                          >
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" dark @click="closeModalAddUser()"> Cancel </v-btn>
        <v-btn
          color="primary"
          :disabled="!form"
          @click="dialog = false"
        >
          Register user
        </v-btn>
      </v-card-actions> -->
    </v-card>
    <v-snackbar v-model="snackbar.value" :multi-line="snackbar.multiLine">
      <h2>
        {{ snackbar.field }}
      </h2>
      <br />
      <p>
        {{ snackbar.text }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { th } from "vuetify/lib/locale";
import { mapMutations } from "vuex";

export default {
  props: ["dialog", "typeAccountUser"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    showCard: true,
    form: false,
    dataForm: {
      email: "",
      phone: "",
      completeAdmin: false,
    },
    snackbar: {
      value: false,
      text: "",
      field: "",
      multiline: true,
    },

    // validations
    rules: {
      req: (value) => !!value || "required.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      max: (v) => (v && v.length <= 30) || "Max 30 characters",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalid",

      // emailMatch: () => ('The email and password you entered don\'t match'),
    },
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    registerUser: function (toAdmin = false) {
      const isAdmin = this.typeAccountUser == 'Client' ? false : true;
      this.showCard = false;
      this.showLoading();
      this.dataForm.completeAdmin = toAdmin;
      this.dataForm.isAdmin = isAdmin;
      const BASE_URL = `${this.ip}link-to-register`;
      axios
        .post(BASE_URL, this.dataForm)
        .then((res) => {
          this.showCard = true;
          this.hideLoading();
          if (res.data.message == "Error in validation") {
            if (res.data.error["email"]) {
              this.snackbar.value = true;
              this.snackbar.text = res.data.error["email"][0];
              this.snackbar.field = "Email";
            }

            if (res.data.error["phone"]) {
              this.snackbar.value = true;
              this.snackbar.text = res.data.error["phone"][0];
              this.snackbar.field = "Phone";
            }
          }

          if (res.data.message == "Email send" && toAdmin == false) {
            const optionsAler = {
              message: "Notification",
              submessage:
                "Please check your email to complete your account registration",
              action: "close",
              path: "/",
            };
            this.openAlert(optionsAler);
            this.closeModalAddUser(true);
          }

          if (res.data.message == "Email send" && toAdmin == true) {
            const optionsAler = {
              message: "generated registration token",
              submessage:
                "",
              action: "close",
              path: "/",
            };
            window.open(res.data.link, '_blank');
            this.openAlert(optionsAler);
            this.closeModalAddUser(true);
          }

        })
        .catch((err) => {
          this.showCard = true;
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    closeModalAddUser: function (saved = false) {
      this.dataForm.email = '';
      this.dataForm.phone = "";
      this.dataForm.completeAdmin = false;
      this.$emit("closeModalAddUser", saved);
    }
  },
};
</script>
<style scoped>
.input {
  margin-bottom: -9px;
}
</style>
