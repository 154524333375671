<template>
  <v-row class="py-6 px-6">
    
    <v-col cols="12">
      <h1 class="primary--text">Review users</h1>
      <navigation :nav="itemsNavigation" />
    </v-col>
    <v-col cols="12">
      
      <v-card class="rounded-lg" elevation="0" color="white">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="2">
              <v-select label="Type account" @change="getUsers()" :items="typeAccountItems" v-model="typeAccount" outlined></v-select>
            </v-col>
            <v-col cols="12" md="2" v-if="typeAccount == 'Client'">
              <v-select label="Filter by status" @change="getUsers()" outlined v-model="status" :items="itemStatus"></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="5">
              <v-text-field label="Search user ..." v-model="search" rounded filled  prepend-inner-icon="mdi-magnify"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-col cols=12>
            <modaladd :dialog="modalAddUser" :typeAccountUser="typeAccount" @closeModalAddUser="closeModalAdd"/>
            <v-btn color="primary" @click="openModalNewUser()"  elevation="0">Register user <v-icon>mdi-account</v-icon></v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
            :headers="headers"
            :search="search"
            :items="users"
            class="elevation-3"
            :items-per-page="100"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="py-3 my-4">
                  <span> #{{ item.id }} </span>
                </td>
                 <td class="py-3 my-4">
                  <span>{{ formatDate(item.created_at) }}</span>
                </td>
                <td class="">
                  <span v-if="item.status != 0">
                    {{ item.firstName}}
                  </span>
                  
                  
                </td>
                <td class="">
                  <span v-if="item.status != 0">
                    {{ item.lastName}}
                  </span>
                 
                  
                </td>
                <td class="">
                  <span>
                    <v-icon small color="primary">mdi-gmail</v-icon>
                    {{ item.username }}
                  </span>
                  
                  
                </td>
                <td class="">
                 <span>
                    <v-icon small color="primary">mdi-phone</v-icon>
                    {{ item.phone }}
                  </span>
                </td>
                <td class="">
                 <span>
                    {{ item.companyName }}
                  </span>
                </td>
                
                <td>
                  <v-chip label small :color="item.color" @click="detailUser(item)">
                    {{item.status_name}}
                  </v-chip>
                </td>
                <td class="">
                  <v-chip label small class="mx-5" color="primary"   @click="startAdvance(item)" v-if="item.rowid_account != 1 && item.ind_create_op">New advance</v-chip>
                  <v-chip label small class="mx-5" color="primary"  @click="completeRegister(item.link_registration)" v-if="item.rowid_account != 1 && item.link_registration != null && item.status == 0">Complete register</v-chip>
                </td>
              </tr>
            </template></v-data-table
          >
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapMutations } from "vuex";

import navigation from "../../components/navigation/breadcrumbs.vue";
import modaladd from "../../components/modals/addUser.vue";
import axios from "axios";
export default {
  data: () => ({
    pagination: {
      page: 1,
      itemsPerPage: 100,
      pageStart: 1,
    },
    itemsNavigation: [
      {
        text: "Dashboard",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Review users",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],
    headers: [
      {
        text: "id",
        align: "start",
        value: "id",
      },
      {
        text: "Reg Date",
        align: "start",
        value: "",
      },
      {
        text: "First Name",
        align: "start",
        value: "firstName",
      },
      {
        text: "Last Name",
        align: "start",
        value: "lastName",
      },
      {
        text: "Email/Username",
        align: "start",
        value: "username",
      },
      {
        text: "Phone no.",
        align: "start",
        value: "phone",
      },
      {
        text: "Company name",
        align: "start",
        value: "companyName",
      },
      {
        text: "Status",
        align: "start",
      },
      {
        text: "",
        align: "start",
      },
    ],
    users: [],
    search: '',
    itemStatus: [
      {text: 'DRAFT', value: 0},
      {text: 'Pending Review', value: 3},
      {text: 'Pending Setup', value: 5},
      {text: 'Setup Complete', value: 1},
      {text: 'All', value: -1}
    ],
    typeAccountItems: [
      'Client', 'Administrator'
    ],
    typeAccount: 'Client',
    status: -1,
    modalAddUser: false,
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getUsers: function () {
      this.showLoading();
      const BASE_URL = process.env.VUE_APP_API_URL + `users/${this.status}/${this.typeAccount}`;
      const requestConfig = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      axios
        .get(BASE_URL, requestConfig)
        .then((res) => {
          this.hideLoading();
          this.users = res.data.data;
        })
        .catch((err) => {
          const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    detailUser: function (User) {
      this.$router.push(`detail-user&id=${User.id}`);
    },
    formatDate: function(date){
        const newDate = new Date(date);
        const day = newDate.getDay() > 10 ? newDate.getDay() : `0${newDate.getDay()}`;
        let month = newDate.getMonth() > 10 ? newDate.getMonth() : `0${newDate.getMonth()}`;
        const year = newDate.getFullYear();

        return `${day}-${month}-${year}`;
    },
    startAdvance(advance) {
      alert('new advance');
    },
    completeRegister:function(link){
      window.open(link, '_blank');
    },
    openModalNewUser:function(){
      this.modalAddUser = true;
    },
    closeModalAdd:function(saved){
      if(saved){
        this.modalAddUser = false;
        this.getUsers();
        return;
      }

      this.modalAddUser = false;
    }
  },
  mounted() {
    this.getUsers();
  },
  components: { navigation, modaladd },
};
</script>
