<template>
  <div>
    <v-dialog v-model="modal" width="500px">
      <v-card elevation="0">
        <v-toolbar dark color="primary" elevation="0">
          <h2 class="">New advance</h2>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <br>
        <v-card-text>
          <v-form>
            <v-select
              label="Advance type"
              class="input"
              :items="items"
              outlined
              v-model="formData.advance_type"
              :rules="[rules.req]"
            ></v-select>
            <!--<v-text-field
              label="Operation date"
              class="input"
              type="date"
              v-model="formData.operation_date"
              outlined
            ></v-text-field>-->
            <v-btn
              color="primary"
              large
              elevation="0"
              block
              @click="registerOperation()"
            >
              <strong>Continue</strong>
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  props: ["modal"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    items: ["Load advance", "Fuel advance"],
    formData: {
      form: false,
      advance_type: "",
      schedule_no: 0,
      operation_date: "",
      username: "",
      rowid_account: "",
    },

    rules: {
      req: (value) => !!value || "required.",
    },
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    registerOperation: function () {
      this.closeModal();
      this.showLoading();
      const USERSESSION = JSON.parse(localStorage.getItem("USERDATA"));
      const TOKEN = USERSESSION.token;
      const USERNAME = USERSESSION.username;
      const ROWID_ACCOUNT = USERSESSION.rowid_account;

      this.formData.username = USERNAME;
      this.formData.rowid_account = ROWID_ACCOUNT;
      let config = {
        headers: {
          Authorization: "Bearer " + TOKEN,
        },
      };
      const BASE_URL = `${this.ip}register-operation`;
      axios
        .post(BASE_URL, this.formData, config)
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            const optionsAler = {
              message: res.data.message,
              action: "redirect",
              path: `/detail-operation/${res.data.id}`,
            };
            console.log(optionsAler, 'options alert');
            this.openAlert(optionsAler);
            this.$emit('closeModal');
          }
        })
        .catch((err) => {
          const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    closeModal: function () {
      this.$emit("closeModal");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.input {
  margin-bottom: -17px;
}
</style>
