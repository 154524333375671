<template>
  <div>
    <v-breadcrumbs :items="nav" class="py-0 px-0 my-0 mx-0">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" :href="item.href" style="font-size:17px;">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon size="30">mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>
<script>
export default {
  props: ["nav"],
};
</script>
<style scoped>

</style>
