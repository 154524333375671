<template>
  <div>
    <v-row dense v-for="contact in businessContact" :key="contact.rowid">
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.firstname"
          dense
          outlined
          label="Firstname"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.lastname"
          dense
          outlined
          label="Lastname"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          :value="
            contact.contact_type_codes == 'MAIN_CONTACT'
              ? 'MAIN CONTACT'
              : contact.contact_type_codes
          "
          disabled
          dense
          outlined
          label="Type contact"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          outlined
          v-model="contact.ssn"
          dense
          label="Social Security No."
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.email"
          dense
          outlined
          label="Email"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.mobile"
          dense
          outlined
          label="Mobile"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.phone"
          dense
          outlined
          label="Phone"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.address"
          v-show="contact.contact_type_codes == 'OWNER'"
          dense
          outlined
          label="Address"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.city"
          v-show="contact.contact_type_codes == 'OWNER'"
          dense
          outlined
          label="City"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.state"
          v-show="contact.contact_type_codes == 'OWNER'"
          dense
          outlined
          label="State"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="contact.zipcode"
          v-show="contact.contact_type_codes == 'OWNER'"
          dense
          outlined
          label="Zip Code"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          outlined
          disabled
          v-model="contact.ownership_percent"
          v-show="contact.contact_type_codes == 'OWNER'"
          dense
          label="Ownership percent"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="updateDataContact(contact)">Update information</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// import VueMask from 'v-mask'
// Vue.use(VueMask);

import { mapMutations } from "vuex";
import axios from "axios";

export default {
  props: ["businessContact"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    updateDataContact: function (contact) {
      this.showLoading()
      const BASE_URL = `${this.ip}update-data-contact`;
      axios
        .post(BASE_URL, contact)
        .then((res) => {
          this.hideLoading();
          if(res.data.status == true){
            const optionsAler = {
              message: res.data.message,
              action: "close",
              path: `/`,
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          this.hideLoading();
          const optionsAler = {
              message: "Error in serve",
              action: "close",
              path: `/`,
          };
          this.openAlert(optionsAler);
          console.log(err);
        });
    },
  }
};
</script>
