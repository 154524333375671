<template>
  <div>
    <v-dialog
      v-model="$store.state.requestInformationModal"
      width="550px"
      persistent
    >
      <v-card mb-1>
        <center>
            <br>
          <v-img 
            src="../../../assets/Logos/logo_fp_h_415.png"
            max-width="300"
            class="ml-3"
          ></v-img>
        </center>
        <br>
        <v-card-title>
          <strong class="primary--text"
            >Look for a broker</strong
          >
        </v-card-title>
        <v-card-subtitle>
          <span class="grey--text"
            >Please enter your email and mobile number to continue</span
          >
        </v-card-subtitle>
        <v-card-text>
          <v-form v-model="information.form">

            <v-text-field
              v-model="information.email"
              class="input"
              :rules="[rules.req]"
              label="Email"
              outlined
            ></v-text-field>

            <v-text-field
              v-model="information.phone"
              class="input"
              :rules="[rules.req]"
              label="Mobile #"
              outlined
            ></v-text-field>

          </v-form>
        </v-card-text>
        <v-card-actions>
           <v-spacer></v-spacer> 
          <!-- <v-btn elevation="0" @click="closeModal()">Cancel</v-btn> -->
          <v-btn
            elevation="6"
            color="primary"
            @click="saveInformation()"
            :disabled="information.form == false"
            >Continue 👍</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialog"],
  data: () => ({
    information: {
      form: false,
      phone: "",
      email: "",
    },
    rules: {
      req: (value) => !!value || "required.",
    },
  }),
  methods: {
    saveInformation: function () {
      localStorage.setItem("phoneRequest", this.information.phone);
      localStorage.setItem("emailRequest", this.information.email);

      location.reload();
    },
    closeModal: function () {
      this.$store.state.requestInformationModal = false;
      location.reload();
    },
  },
};
</script>
<style scoped>
.input {
  margin-bottom: -12px;
}
</style>
