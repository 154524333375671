<template>
  <div>
    <v-dialog persistent width="450" v-model="dialog">
      <v-card class="rounded-lg">
        <v-toolbar dark color="primary" elevation="0">
          <h2 class="">New Addition</h2>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <br />
        <v-card-text>
          <v-form v-model="formData.form">
             <v-select :items="itemsAddition" outlined label="Type addition" v-model="formData.typeAddition"></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="ligth" elevation="0" @click="closeDialog()">Cancel</v-btn>
          <v-btn
            color="primary"
            elevation="6"
            >Confirm addition</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['dialog'],
  data: () => ({
    formData: {
        form: false,
        typeAddition: ''
    },
    itemsAddition: ['Lumper', 'Detention', 'Tonu']
  }),
  methods: {
    closeDialog: function(){
        this.$emit('closeDialog');
    }
  },
};
</script>
