<template>
  <div>
    <v-card-text>
      <v-form v-model="formDataContact.form">
        <v-row dense>
          <v-col cols="12" md="12">
            <v-alert elevation="0" text color="info" dense class="rounded-lg">
              <v-row align="center" no-gutters dense>
                <v-col class="grow">
                  <h4>Main contact is also the owner</h4>
                  <!--<p>Select to bring the registered data</p>-->
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="shrink">
                  <v-switch
                    v-model="formDataContact.isOwner"
                    inset
                    @change="getDataContact()"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.firstname"
              outlined
              :rules="[rules.req]"
              label="Firstname *"
               maxlength="30"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.lastname"
              outlined
              :rules="[rules.req]"
              label="Lastname *"
               maxlength="30"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.ssn"
              outlined
              :rules="[rules.req]"
              label="Social Security No. *"
               maxlength="11"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.email"
              outlined
              :rules="[rules.req]"
              label="Email *"
               maxlength="80"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.mobile"
              outlined
              :rules="[rules.req]"
              label="Mobile *"
               maxlength="10"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.phone"
              outlined
              label="Phone"
               maxlength="10"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.address"
              outlined
              :rules="[rules.req]"
              label="Address *"
               maxlength="80"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.city"
              outlined
              :rules="[rules.req]"
              label="City *"
               maxlength="40"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.state"
              outlined
              :rules="[rules.req]"
              label="State *"
               maxlength="30"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.zipcode"
              outlined
              :rules="[rules.req]"
              label="Zip Code *"
               maxlength="5"
            ></v-text-field>
          </v-col>
          <!--<v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.country"
              outlined
              :rules="[rules.req]"
              label="Country"
              disabled
            ></v-text-field>
          </v-col>-->
          <v-col cols="12" md="4">
            <v-select
              outlined
              :rules="[rules.req]"
              :items="itemsOwnership"
              label="Ownership percent *"
              v-model="formDataContact.ownership_percent"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="formDataContact.ownership_percent == 'Less than 100%'">
          <v-col cols="12">
            <v-alert elevation="0" text color="darkgrey" dense class="rounded-lg">
              <v-row align="center" no-gutters dense>
                <v-col class="grow">
                  <h4>Principal / owner 2 information</h4>
                  <!--<p>Select to bring the registered data</p>-->
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.firstnametwo"
              outlined
              :rules="[rules.req]"
              label="Firstname *"
               maxlength="30"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.lastnametwo"
              outlined
              :rules="[rules.req]"
              label="Lastname *"
               maxlength="30"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.ssntwo"
              outlined
              :rules="[rules.req]"
              label="Social Security No. *"
               maxlength="11"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.emailtwo"
              outlined
              :rules="[rules.req]"
               maxlength="60"
              label="Email *"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.mobiletwo"
              outlined
              :rules="[rules.req]"
              label="Mobile *"
               maxlength="10"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.phonetwo"
              outlined
              label="Phone"
               maxlength="10"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.addresstwo"
              outlined
              :rules="[rules.req]"
              label="Address *"
               maxlength="80"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.citytwo"
              outlined
              :rules="[rules.req]"
              label="City *"
               maxlength="40"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.statetwo"
              outlined
              :rules="[rules.req]"
              label="State *"
               maxlength="30"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.zipcodetwo"
              outlined
              :rules="[rules.req]"
              label="Zip Code *"
               maxlength="5"
            ></v-text-field>
          </v-col>
          <!--<v-col cols="12" md="4">
            <v-text-field
              v-model="formDataContact.countrytwo"
              outlined
              :rules="[rules.req]"
              label="Country"
              disabled
            ></v-text-field>
          </v-col>-->
          <v-col cols="12" md="4">
            <v-select
              outlined
              :rules="[rules.req]"
              :items="itemsOwnership[1]"
              label="Ownership percent *"
              v-model="formDataContact.ownership_percenttwo"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!formDataContact.form"
        color="primary"
        elevation="0"
        large
        @click="registerBusinessAccountContact()"
      >
        Owner(s) profile complete
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  props: ["formDataContact", "rules"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    itemsOwnership: ["100%", "Less than 100%"],
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getDataContact() {
      if (this.formDataContact.isOwner) {
        this.showLoading();
        const token = this.$route.params.token;
        const BASE_URL = `${this.ip}get-business-contact&token=${token}`;
        axios
          .get(BASE_URL)
          .then((res) => {
            const data = res.data.data;
            this.hideLoading();
            if (data.length > 0) {
              this.formDataContact.firstname = data[0].firstname;
              this.formDataContact.lastname = data[0].lastname;
              this.formDataContact.email = data[0].email;
              this.formDataContact.mobile = data[0].mobile;
              this.formDataContact.phone = data[0].phone;
              this.formDataContact.id_contact = data[0].rowid;
            }
          })
          .catch((err) => {
            const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
            };
            this.openAlert(optionsAler);
            this.hideLoading();
            console.log(err);
          });
      } else {
        this.formDataContact.firstname = "";
        this.formDataContact.lastname = "";
        this.formDataContact.email = "";
        (this.formDataContact.mobile = ""), (this.formDataContact.phone = "");
        this.formDataContact.id_contact = null;
      }
    },

    registerBusinessAccountContact: function(){
      this.$emit('registerOwnerContact')
    }
  },
};
</script>
