<template>
  <div>
    <v-form v-model="formData.form">
      <v-row dense>
        <v-col cols="12">
          <h2 class="primary--text pb-2">Client information</h2>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            outlined
            label="Account type"
            :items="itemsTypeAccount"
            v-model="formData.account_type"
          ></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            outlined
            label="Client code"
            v-model="formData.client_code"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <h2 class="primary--text pb-2">Client terms</h2>
        </v-col>

        <v-col cols="12" md="3">
            <currencyinput
            :options="{ currency: 'USD' }"
            label="Receivable finance facility ($)"
            v-model="formData.receivable_fin_facility"
          ></currencyinput>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            max="100"
            label="Advance rate (%)"
            v-model="formData.advance_rate"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            label="Reserve (%)"
            v-model="formData.reserve"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            max="100"
            label="Factoring fee (%)"
            v-model="formData.factoring_fee"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            label="Factoring fee period (days)"
            v-model="formData.factoring_fee_period"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            max="100"
            label="Collection fee (%)"
            v-model="formData.collection_fee"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            label="Collection fee start period (days)"
            disabled
            :value="parseInt(formData.factoring_fee_period) + 1"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            label="Collection fee end period (days)"
            v-model="formData.collection_fee_period"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <currencyinput
            :options="{ currency: 'USD' }"
            label="Late Payment charge ($)"
            v-model="formData.late_payment_charge"
          ></currencyinput>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            label="Late payment date (days)"
            v-model="formData.late_payment_days"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            label="Invoice terms (days)"
            v-model="formData.invoice_terms"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            label="Relationship Period (days)"
            v-model="formData.relationship_period"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            max="100"
            label="PO advance (%)"
            v-model="formData.po_advance"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            outlined
            type="number"
            min="35"
            max="100"
            label="PO advance fee (%)"
            v-model="formData.po_advance_fee"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <h2 class="primary--text pb-2">Utilized fees</h2>
        </v-col>
        <v-col cols="12" md="3">
          <currencyinput
            :options="{ currency: 'USD' }"
            label="EFT fee ($)"
            v-model="formData.etf_fee"
          ></currencyinput>
        </v-col>

        <v-col cols="12" md="3">
          <currencyinput
            :options="{ currency: 'USD' }"
            label="Re-Documentation Fee ($)"
            v-model="formData.re_docymentation_fee"
          ></currencyinput>
        </v-col>

        <v-col cols="12" md="3">
          <currencyinput
            :options="{ currency: 'USD' }"
            label="ACH fee ($)"
            v-model="formData.ach_fee"
          ></currencyinput>
        </v-col>

        <v-col cols="12" md="3">
          <currencyinput
            :options="{ currency: 'USD' }"
            label="UCC filing fee ($)"
            v-model="formData.ucc_fee"
          ></currencyinput>
        </v-col>

        <v-col cols="12" md="3">
          <currencyinput
            :options="{ currency: 'USD' }"
            label="Registration account setup ($)"
            v-model="formData.setup_fee"
          ></currencyinput>
        </v-col>

        <v-col cols="12" md="3">
          <currencyinput
            :options="{ currency: 'USD' }"
            label="AS proccessing fee ($)"
            v-model="formData.as_processing_fee"
          ></currencyinput>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="pb-0">
      <v-spacer></v-spacer>
      <v-btn color="primary" large elevation="0" @click="updateOperationData()"
        >Confirm</v-btn
      >
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import currencyinput from "../../inputCurrency.vue";

export default {
  props: ["businessData", "formData", "actionUser"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    itemsTypeAccount: [
      "Freight Factoring",
      "Domestic Factoring",
      "Trade Finance/Int'l Factoring",
    ],
  }),
  components: {
    currencyinput,
  },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    updateOperationData: function () {
      this.formData.account_id = this.businessData.rowid;
      if (
        parseInt(this.formData.advance_rate) +
          parseInt(this.formData.reserve) ==
        100.00
      ) {
        this.showLoading();
        const BASE_URL = `${this.ip}save-factoring-data`;
        axios
          .post(BASE_URL, this.formData)
          .then((res) => {
            if (res.data.status == true) {
              const optionsAler = {
                message: "Information saved.",
                action: "close",
                path: `/`,
              };
              this.openAlert(optionsAler);
              this.hideLoading();
              this.$emit("chageActions");
            }
          })
          .catch((err) => {
            const optionsAler = {
              message: "Error in server",
              action: "close",
              path: `/`,
            };
            this.openAlert(optionsAler);
            this.hideLoading();
            console.log(err);
          });
      } else {
        const optionsAler = {
          message: "Advance Fee + Reserve Fee must be 100%",
          action: "close",
          path: `/`,
        };
        this.openAlert(optionsAler);
      }
    },
  },
  mounted() {
    this.formData.account_id = this.businessData.rowid;
  },
};
</script>
