<template>
  <div>
    <v-dialog v-model="dialogDecline" width="450" persistent    >
      <v-card class="rounded-lg">
        <v-toolbar dark color="primary" elevation="0">
          <h2 class="">Decline porfile account</h2>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <br>
        <v-card-text>
            <v-form v-model="form">
                <v-textarea outlined label="note" :rules="[rules.req]" v-model="note"></v-textarea>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="ligth" elevation="0" @click="close()">Cancel</v-btn>
            <v-btn color="primary" :disabled="!form" @click="declineUser()" elevation="6">Decline account</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import headericon from "../../../headers/headerIcon.vue";

export default {
  props: ["dialogDecline"],
  data: () => ({
    note: "",
    form: false,

    rules: {
      req: (value) => !!value || "required.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      max: (v) => (v && v.length <= 30) || "Max 30 characters",
    },
  }),
  components: { headericon },
  methods: {
    declineUser: function () {
        this.$emit("declineUser", this.note);
    },
    close: function(){
        this.note = '';
        this.$emit("closeDecline", this.note);
    }
  },
};
</script>
