<template>
  <div>
    <v-dialog persistent width="350px" v-model="$store.state.alert.alert">
      <v-card >
        <v-card-text>
            <center>
          <v-row dense justify="center" class="pt-4">
              <v-col cols="12">
                <v-avatar size="80" :color="$store.state.alert.colorAvatar">
                  <v-icon :color="$store.state.alert.colorIcon" size="40">
                    {{ $store.state.alert.icon }}
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="12">
                <strong class="title">{{ $store.state.alert.message }}</strong>
                <p class="subtitle"> {{ $store.state.alert.submessage }} </p>
              </v-col>
              <v-col cols="12">
                <v-btn block color="primary" @click="close()" elevation="0"> <strong>Close</strong> </v-btn>
              </v-col>
          </v-row>
            </center>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
  methods: {
    ...mapMutations(['closeAlert']),
    close: function () {
        if( this.$store.state.alert.action == 'close' ){
            this.closeAlert();
        }

        if(this.$store.state.alert.action == 'redirect'){
            this.closeAlert();
            this.$router.push(this.$store.state.alert.path);
        }

        if(this.$store.state.alert.action == 'refresh'){
            this.closeAlert();
            window.reload();
        }
    },
  },
};
</script>
<style scoped>
strong.title {
    font-size: 15px !important;
    font-weight: bold;
    font-family: 'Poppins', sans-serif !important;
}

p.subtitle {
    font-family: 'Poppins', sans-serif !important;
}

</style>
