<template>
  <div>
    <v-card-text>
      <v-form v-model="formDataBusiness.form">
        <v-row dense>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataBusiness.name"
              outlined
              :rules="[rules.req]"
              label="Business name *"
               maxlength="60"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataBusiness.dba"
              outlined
              label="DBA (Doing Business As)"
               maxlength="60"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              v-model="formDataBusiness.biz_type"
              :items="listTypeBiz"
              outlined
              :rules="[rules.req]"
              label="Business type *"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataBusiness.tax_id_ein"
              outlined
              maxlength="11"
              :rules="[rules.req]"
              :label="formDataBusiness.biz_type != 'Sole Propietorship' ? 'Tax ID No.-EIN *' : 'SSN *'"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataBusiness.address"
              outlined
              :rules="[rules.req]"
              label="Address *"
              maxlength="80"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataBusiness.city"
              outlined
              :rules="[rules.req]"
              label="City *"
               maxlength="40"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataBusiness.state"
              outlined
              :rules="[rules.req]"
              label="State *"
               maxlength="30"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="formDataBusiness.zipcode"
              outlined
              :rules="[rules.req]"
              label="Zip Code *"
               maxlength="5"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <!-- <v-col cols="12">
                <h2 class="primary--text">Operation information</h2>
          </v-col> -->
          <v-col cols="12" md="4">
            <v-text-field outlined label="USDOT No. *" 
            v-model="formDataBusiness.usdnumber" :rules="[rules.req]"
             maxlength="15">

            </v-text-field>
          </v-col>
           <v-col cols="12" md="4">
            <v-text-field outlined label="Motor Carrier No. *" 
            v-model="formDataBusiness.mcnumber"
            maxlength="15">

            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-select  :items="items_equipment"  outlined label="Equipment Type *" 
            v-model="formDataBusiness.equipment_type" :rules="[rules.req]">

            </v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select  :items="items_authorty"  outlined label="Authorty Type *" 
            v-model="formDataBusiness.authorty_type" :rules="[rules.req]">

            </v-select>
          </v-col>
           <v-col cols="12" md="4">
            <v-text-field type="number" min="0" max="100"  outlined label="No. Trucks *" 
            v-model="formDataBusiness.numbertrucks" :rules="[rules.req]">

            </v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <h2 class="primary--text">Main contact</h2>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field label="First name *" maxlength="30" v-model="formDataBusiness.firstname" outlined :rules="[rules.req]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field label="Last name *"  maxlength="30" v-model="formDataBusiness.lastname" outlined :rules="[rules.req]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field label="Email *"  maxlength="60" outlined v-model="formDataBusiness.email" :rules="[rules.req, rules.email]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field label="Mobile no. *"  maxlength="10" v-model="formDataBusiness.mobile" outlined :rules="[rules.req]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field label="Telephone no."  maxlength="10" v-model="formDataBusiness.telephone" outlined></v-text-field>
            </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        elevation="6"
        :disabled="!formDataBusiness.form"
        @click="registerBusinessContact()"
        large
      >
        <strong>Confirm business information</strong>
      </v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
  props: ["rules", "formDataBusiness"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    items_equipment: [],
    items_authorty: [],
    listTypeBiz: [],
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading"]),
    registerBusinessContact:function(){
      this.$emit('registerDataBusiness')
    },
    getListSelect: async function (idItems){
      const BASE_URL = `${this.ip}get-list-by-class&class=${idItems}`;
      const response = await axios.get(BASE_URL);
      return response.data.data;
    }
  },
  async mounted(){
    this.showLoading();
    this.items_equipment = await this.getListSelect('EQUIPMENT_TYPE');
    this.items_authorty = await this.getListSelect('AUTHORITY_TYPE');
    this.listTypeBiz = await this.getListSelect('TYPE_BIZ')
    this.hideLoading();
  }

};
</script>
