<template>
  <v-row
    class="py-0 px-0 my-0 mx-0 white--text container-login"
    style="background-color: #0072c3"
  >
    <v-col
      cols="12"
      md="8"
      class="d-none d-md-block"
      style="position: relative"
    >
      <div class="message">
        <v-row>
          <v-col cols="12" md="12">
            <v-card class="rounded-lg" elevation="0">
              <v-card-text>
                <center>
                  <img width="300" src="../../assets/Logos/logo_fb_h_500.png" />
                </center>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <strong class="title-page">To look for a broker and verify its status please visit 👇</strong> 
          </v-col>
          <v-col cols="12">
            <v-btn outlined color="white" @click="push('/search-brokers')" block x-large><strong>Search Brokers</strong></v-btn>
          </v-col>
        </v-row>
        <br>
        
      </div>
    </v-col>
    <v-col cols="12" md="4" style="background-color: white" class="black--text">
      <recover />
      <v-form class="py-5 px-5" dense v-model="formLogin.form">
        <v-col cols="12">
 

          <center>
            <img src="../../assets/Logos/logo_fp_h_415.png" width="300" />
          </center>

          <br />
          <h2>Sign In</h2>
          <!--<p class="grey--text">
            Don't have an account? Please
            <span @click="push('register')" class="primary--text path"
              >Sign Up</span
            >
          </p>  -->
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            :rules="[rules.req, rules.email]"
            v-model="formLogin.username"
            label="Email"
          ></v-text-field>
          <v-text-field
            outlined
            :rules="[rules.req]"
            v-on:keyup.enter="login()"
            v-model="formLogin.password"
            label="Password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-btn
            :disabled="!formLogin.form"
            block
            x-large
            elevation="0"
            @click="login()"
            color="primary"
            >Sign in</v-btn
          >
        </v-col>
        <v-col cols="12">
          <p
            class="primary--text forgot"
            @click="$store.state.recoverPassword = true"
          >
            Forgot your password?
          </p> 
          FactorPlus.Net &copy; {{ this.version }}
        </v-col>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import recover from "../../components/modals/login/recorverPassword.vue";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    show1: false,
    version: process.env.VUE_APP_VERSION,
    ip: process.env.VUE_APP_API_URL,
    formLogin: {
      form: false,
      username: "",
      password: "",
    },

    rules: {
      req: (value) => !!value || "required.",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalid",
    },
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert", "closeAlert"]),
    login: function () {
      this.showLoading();
      const BASE_URL = `${this.ip}login`;
      axios
        .post(BASE_URL, this.formLogin)
        .then((res) => {
          this.hideLoading();

          if (res.data.status == false) {
            const optionsAler = {
              message: res.data.message,
              action: "close",
              path: "/",
            };
            this.formLogin.password = "";
            this.openAlert(optionsAler);
          }
        
          else if (res.data.status == true) {  // && res.data.message == "login"
            localStorage.setItem(
              "USERDATA",
              JSON.stringify(res.data["session user"])
            );
            localStorage.setItem(
              "USERMENU",
              JSON.stringify(res.data.user_menu)
            );
            this.$router.push(res.data.user_ini_page);
          }

        })
        .catch((err) => {
          const optionsAler = {
              message: err.response.data.message,
              action: "close",
              path: "/",
            };
          this.formLogin.password = "";
          this.formLogin.username = "";
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    push: function (path) {
      this.$router.push(path);
    },
  },
  components: { recover },
};
</script>
<style scoped>
.path {
  cursor: pointer;
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color:white;
  border-radius: 20px;
  height: 60%;
  width: 80%;
  align-items: center; */
}

.container-login {
  height: 100vh;
}

.title-page {
  font-size: 30px; 
}

.forgot {
  cursor: pointer;
}
</style>
