<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6" class="pt-12">
        <br />
        <br />
        <v-card elevation="0" class="rounded-lg">
          <v-card-text>
            <!-- {{ infoAccount }} -->
            <v-row>
              <v-col cols="12">
                <v-list>
                  <v-list-item>
                    <v-list-item-avatar size="90">
                      <v-avatar size="90" style="border: 2px solid #ddd">
                        <v-avatar
                          size="80"
                          color="primary"
                          style="border: 2px solid #ddd"
                        >
                          <h1 class="white--text">
                            {{ infoAccount.firstName.substring(0, 1) }}
                          </h1>
                        </v-avatar>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="darkgrey--text">
                        <h2 class="darkgrey--text">
                          {{
                            infoAccount.firstName + " " + infoAccount.lastName
                          }}
                        </h2>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span class="mr-5">
                          <v-icon color="primary" small>mdi-gmail</v-icon>
                          {{ infoAccount.username }}
                        </span>
                        <span>
                          <v-icon color="primary" small>mdi-phone</v-icon>
                          {{ infoAccount.phone }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="primary--text">Login Settings</h3>
              </v-col>
              <v-col cols="12">
                <h4 class="grey--text">Change Password</h4>
                <span
                  >It is recommended to use a strong password that you do not
                  use anywhere else.</span
                >
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="dataForm.password"
                  :append-icon="
                    dataForm.typePassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :rules="[rules.required, rules.min]"
                  :type="dataForm.typePassword ? 'text' : 'password'"
                  name="input-password"
                  label="Password"
                  outlined
                  @click:append="dataForm.typePassword = !dataForm.typePassword"
                ></v-text-field>
                <password
                  v-model="dataForm.password"
                  :toggle="true"
                  @score="showScore"
                  @feedback="showFeedback"
                  :strength-meter-only="true"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="dataForm.password_verify"
                  :append-icon="
                    dataForm.typePasswordVerify ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  :rules="[rules.required, rules.min]"
                  :type="dataForm.typePasswordVerify ? 'text' : 'password'"
                  name="input-password"
                  label="Verify Password"
                  outlined
                  @click:append="
                    dataForm.typePasswordVerify = !dataForm.typePasswordVerify
                  "
                ></v-text-field>
                <password
                  v-model="dataForm.password_verify"
                  :toggle="true"
                  @score="showScore"
                  @feedback="showFeedback"
                  :strength-meter-only="true"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="updatePassword()"
              elevation="0"
              color="primary"
              large
              :disabled="
                dataForm.scorePassword < 3 ||
                dataForm.password != dataForm.password_verify
              "
              >Save changes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import Password from "vue-password-strength-meter";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    infoAccount: [],
    ip: process.env.VUE_APP_API_URL,
    dataForm: {
      form: false,
      id: "",
      username: "",
      password: "",
      password_verify: "",
      typePassword: false,
      typePasswordVerify: false,
      scorePassword: 0,
    },
    rules: {
      req: (value) => !!value || "required.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      max: (v) => (v && v.length <= 30) || "Max 30 characters",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalid",
    },
  }),
  components: { Password },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getDataAccount: function () {
      this.infoAccount = JSON.parse(localStorage.getItem("USERDATA"));
      delete this.infoAccount.token;
      console.log(this.infoAccount, 'data');
    },
    updatePassword: function () {
      this.showLoading();
      this.dataForm.id = this.infoAccount.id;
      this.dataForm.username = this.infoAccount.username;
      const BASE_URL = `${this.ip}update-password`;
      axios
        .post(BASE_URL, this.dataForm)
        .then((res) => {
          this.hideLoading();
          const optionsAler = {
            message: res.data.message,
            action: "close",
            path: "/",
          };
          this.dataForm.password = '';
          this.dataForm.password_verify = '';
          this.dataForm.typePassword = false;
          this.dataForm.typePasswordVerify = false;
          this.dataForm.scorePassword = 0;
          this.openAlert(optionsAler);
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    showFeedback({ suggestions, warning }) {
      // console.log("🙏", suggestions);
      // console.log("⚠", warning);
    },
    showScore(score) {
      this.dataForm.scorePassword = score;
    },
  },
  mounted() {
    this.getDataAccount();
  },
};
</script>
