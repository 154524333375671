<template>
  <v-container>
    <br />
    <br />
    <v-row justify="center" class="mt-12">
      <v-col cols="12" md="4">
        <v-card elevation="0" class="rounded-lg">
          <v-card-title>
            <h2 class="primary--text">Recover your password</h2>
          </v-card-title>
          <v-card-subtitle>
            <span class="grey--text">Write your new password</span>
          </v-card-subtitle>
          <v-card-text>
            <v-form v-model="formDataUser.form">
              <v-text-field
                v-model="formDataUser.password"
                :append-icon="
                  formDataUser.typePassword ? 'mdi-eye' : 'mdi-eye-off'
                "
                :rules="[rules.required, rules.min]"
                :type="formDataUser.typePassword ? 'text' : 'password'"
                name="input-password"
                label="Password"
                outlined
                @click:append="
                  formDataUser.typePassword = !formDataUser.typePassword
                "
              ></v-text-field>
              <password
                v-model="formDataUser.password"
                :toggle="true"
                @score="showScore"
                @feedback="showFeedback"
                :strength-meter-only="true"
              />

              <v-text-field
                v-model="formDataUser.password_verify"
                :append-icon="
                  formDataUser.typePasswordVerify ? 'mdi-eye' : 'mdi-eye-off'
                "
                :rules="[rules.required, rules.min]"
                :type="formDataUser.typePasswordVerify ? 'text' : 'password'"
                name="input-password"
                label="Verify Password"
                outlined
                @click:append="
                  formDataUser.typePasswordVerify =
                    !formDataUser.typePasswordVerify
                "
              ></v-text-field>
              <password
                v-model="formDataUser.password_verify"
                :toggle="true"
                @score="showScore"
                @feedback="showFeedback"
                :strength-meter-only="true"
              />
            </v-form>
          </v-card-text>
          <center>
            <v-btn
              color="primary"
              @click="updatePassword()"
             :disabled="!formDataUser.form || formDataUser.scorePassword < 3 || formDataUser.password != formDataUser.password_verify"
              x-large
              elevation="0"
              >Update password</v-btn
            >
            <br />
            <br />
            <p>
              You can too
              <a href="/">Log in</a>
            </p>
          </center>
          <br />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Password from "vue-password-strength-meter";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    token: "",
    verified: "",
    ip: process.env.VUE_APP_API_URL,

    formDataUser: {
      form: false,
      password: "",
      password_verify: '',
      typePassword: false,
      typePasswordVerify: false,
      scorePassword: 0,
      firstName: "",
      lastName: "",
      username: "",
      id: ""
    },

    rules: {
      req: (value) => !!value || "Requerido.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      max: (v) => (v && v.length <= 20) || "Max 20 caracteres",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalido",
    },
  }),
  components: { Password },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    validateToken() {
      this.showLoading();
      const BASE_URL = `${this.ip}validate-token-recover-password/${this.verified}/${this.token}`;
      axios.get(BASE_URL).then(res => {
        this.hideLoading();
        this.formDataUser.username = res.data.user[0].username;
        this.formDataUser.id = res.data.user[0].id;
      }).catch(err => {
        const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
        };
        this.openAlert(optionsAler);
        console.log(err);
        this.hideLoading();
      })
    },
    updatePassword() {
      this.showLoading();
      const BASE_URL = `${this.ip}update-password`;
      axios
        .post(BASE_URL, this.formDataUser)
        .then((res) => {
          this.hideLoading();
          if(res.data.status == true){
            const optionsAler = {
              message: "Password updated",
              action: "redirect",
              path: `/`,
            };

            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    showFeedback({ suggestions, warning }) {
      // console.log("🙏", suggestions);
      // console.log("⚠", warning);
    },
    showScore(score) {
      this.formDataUser.scorePassword = score;
    },
  },
  mounted() {
    this.token = this.$route.params.token;
    this.verified = this.$route.params.verified;

    this.validateToken();
  },
};
</script>
