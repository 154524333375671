<template>
  <v-row dense>
    <v-col cols="12" md="4">
      <v-text-field
        outlined
        dense
        v-model="businessData.name"
        label="Business name"
        maxlength="60"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        outlined
        dense
        v-model="businessData.doing_biz_as"
        label="DBA (Doing Business As)"
        maxlength="30"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-select
        outlined
        dense
        :items="listTypeBiz"
        v-model="businessData.biz_type"
        label="Business type"
      ></v-select>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        outlined
        v-model="businessData.tax_id_ein"
        dense
        maxlength="11"
        label="Tax ID No.-EIN"
      ></v-text-field>
    </v-col>
    <!-- <v-col cols="12" md="4">
                <v-text-field outlined v-model="businessData.address"  label="Social security"></v-text-field>
              </v-col> -->
    <v-col cols="12" md="4">
      <v-text-field
        outlined
        v-model="businessData.address"
        dense
        maxlength="80"
        label="Address"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="businessData.city"
        dense
        outlined
        label="City"
        maxlength="40"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        outlined
        v-model="businessData.state"
        dense
        maxlength="40"
        label="State"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        v-model="businessData.zipcode"
        dense
        outlined
        label="Zip Code"
        maxlength="5"
      ></v-text-field>
    </v-col>
    <!--<v-col cols="12" md="4">
      <v-text-field
        v-model="businessData.country"
        disabled
        dense
        outlined
        label="Country"
      ></v-text-field>-->
    <!-- </v-col> -->
    <v-col cols="12" md="4">
      <v-text-field
        dense
        outlined
        v-model="businessData.mc_number"
        label="Mc number"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4">
      <v-text-field
        dense
        outlined
        v-model="businessData.usdot_number"
        label="Usdot number"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" class="aling-items-rigth">
      <v-card-actions class="mx-0 my-0 px-0 py-0">
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary" @click="updatedData()"
          >Update information</v-btn
        >
      </v-card-actions>
    </v-col>
    <v-col cols="12">
      <br />
      <br />
      <v-card outlined class="rounded-lg">
        <v-card-title class="primary--text">
          <strong>
            Documents
            <v-btn
              @click="openDialogAddDocument()"
              fab
              color="primary"
              class="mx-2"
              x-small
              elevation="0"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn></strong
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="3"
              v-for="document in documents"
              :key="document.rowid"
            >
              <center>
                <v-card outlined>
                  <v-card-text>
                    <small v-if="document.resource_type == 'DRIVER_LICENSE'"
                      >Driver license</small
                    >
                    <small v-if="document.resource_type == 'MC_NUMBER'"
                      >Mc number</small
                    >
                    <small v-if="document.resource_type == 'VOIDED_CHECK'"
                      >Voided check</small
                    >
                    <small
                      v-if="document.resource_type == 'CERTIFICATE_LIABILITY'"
                      >Certificate liability</small
                    >
                    <small v-if="document.resource_type == 'OWNER_SIGNATURE'"
                      >Owner signature</small
                    >
                    <small v-if="document.resource_type == 'contract_noa'"
                      >Contract noa</small
                    >
                    <small v-if="document.resource_type == 'contract_express'"
                      >Contract express</small
                    >
                    <br />
                    <v-icon size="50" color="error">mdi-file-pdf-box</v-icon>
                    <br />
                    <span>
                      <a
                        style="text-decoration: none"
                        :href=getAppUrl(document.resource_url)
                        target="_document"
                        >Click to view</a
                      >
                    </span>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                      <v-icon color="error" v-if="document.ind_can_delete" @click="deleteDocument(document)"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </center>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-dialog persistent v-model="dialogAddDocument" width="450">
        <v-card class="rounded-lg">
          <v-toolbar dark color="primary" elevation="0">
            <h2 class="">Decline porfile account</h2>
            <v-spacer></v-spacer>
            <v-btn color="white" icon @click="closeDialogAddDocument()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <br />
          <v-card-text>
            <v-form>
              <center>
                <v-select
                  :items="itemsTypeDocument"
                  v-model="typeDocument"
                  label="Type document"
                  outlined
                ></v-select>
                <v-card outlined class="rounded-lg">
                  <v-card-text>
                    <!-- <small>US Dot/MC Number *</small> <br /> -->
                    <span>
                      {{ formDocuments[0].nameFile }}
                    </span>
                    <br />
                    <v-icon
                      :color="formDocuments[0].nameFile == '' ? 'grey' : 'red'"
                      size="70"
                      >mdi-file-pdf-box</v-icon
                    >
                    <br />
                    <br />

                    <label
                      for="choose00"
                      class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                    >
                      Choose document
                    </label>
                    <input
                      type="file"
                      @change="upFile"
                      id="choose00"
                      accept=".pdf"
                      style="display: none"
                    />
                  </v-card-text>
                </v-card>
              </center>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="ligth" elevation="0" @click="closeDialogAddDocument()"
              >Cancel</v-btn
            >
            <v-btn color="primary" elevation="6" @click="upLoadFile()"
              >Upload file</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  props: ["businessData", "userData"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    site_url: process.env.VUE_APP_SITE_URL,
    documents: [],
    dialogAddDocument: false,
    typeDocument: "DRIVER_LICENSE",
    itemsTypeDocument: [
      "DRIVER_LICENSE",
      "MC_NUMBER",
      "VOIDED_CHECK",
      "CERTIFICATE_LIABILITY",
      "OWNER_SIGNATURE",
    ],
    listTypeBiz: [],
    formDocuments: [{ nameFile: "", base64: null, type: "" }],
  }),
  methods: {
    ...mapMutations(["openAlert", "hideLoading", "showLoading"]),
    getDocuments: function (id) {
      const BASE_URL = `${this.ip}get-documents/${id}`;
      axios
        .get(BASE_URL)
        .then((res) => {
          if (res.data.status == true) {
            this.documents = res.data.data;
          } else {
            const optionsAler = {
              message: "Error in saved",
              action: "close",
              path: `/`,
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server",
            action: "close",
            path: `/`,
          };
          this.openAlert(optionsAler);
          console.log(err);
        });
    },
    updatedData: function () {
      const BASE_URL = `${this.ip}updated-business-information`;
      this.showLoading();
      axios
        .post(BASE_URL, this.businessData)
        .then((res) => {
          this.hideLoading();
          if (res.data.status) {
            const optionsAler = {
              message: res.data.message,
              action: "close",
              path: `/`,
            };
            this.openAlert(optionsAler);
          } else {
            const optionsAler = {
              message: "Error in server",
              action: "close",
              path: `/`,
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          this.hideLoading();
          const optionsAler = {
            message: "Error in server",
            action: "close",
            path: `/`,
          };
          this.openAlert(optionsAler);
          console.log(err);
        });
    },


    getAppUrl: function (file_url) {
      //return this.site_url + 'public' + file_url
      return this.site_url + 'server.php/viewFile?path=' + file_url
    },

    deleteDocument: function (doc) {

      if(!confirm("Please confirm to continue."))
         return false;

      this.showLoading();
      const BASE_URL = `${this.ip}delete-document`;
      axios
        .post(BASE_URL, { rowid: doc.rowid })
        .then((res) => {
          this.hideLoading();
          const optionsAler = {
            message: res.data.message,
            action: "close",
            path: `/`,
          };
          this.openAlert(optionsAler);
          this.getDocuments(this.businessData.rowid);
        })
        .catch((err) => {
          this.hideLoading();
          const optionsAler = {
            message: "Error in server",
            action: "close",
            path: `/`,
          };
          this.openAlert(optionsAler);
          console.log(err);
        });
    },
    upLoadFile: async function () {
      this.showLoading();

      const BASE_URL = `${this.ip}upload-files-admin`;
      axios
        .post(BASE_URL, {
          documents: this.formDocuments,
          user: this.userData.id,
        })
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            const optionsAler = {
              message: "Document saved",
              action: "close",
              path: `/`,
            };

            this.openAlert(optionsAler);
            this.formDocuments = [{ nameFile: "", base64: null, type: "" }];
            this.getDocuments(this.businessData.rowid);
            this.closeDialogAddDocument();
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          console.log(err);
        });
    },
    openDialogAddDocument: function () {
      this.dialogAddDocument = true;
    },
    closeDialogAddDocument: function () {
      this.dialogAddDocument = false;
      this.formDocuments = [{ nameFile: "", base64: null, type: "" }];
      this.typeDocument = 'DRIVER_LICENSE'
    },
    upFile: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formDocuments[0].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formDocuments[0].base64 = e.target.result;
        this.formDocuments[0].type = this.typeDocument;
      };
    },
    getListSelect: async function (idItems){
      const BASE_URL = `${this.ip}get-list-by-class&class=${idItems}`;
      const response = await axios.get(BASE_URL);
      return response.data.data;
    }
  },
  async mounted() {
    this.showLoading();
    this.getDocuments(this.businessData.rowid);
    this.listTypeBiz = await this.getListSelect('TYPE_BIZ')
    this.hideLoading();
  },
};
</script>
