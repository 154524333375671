<template>
  <div>
    <v-dialog v-model="modal" width="700" persistent scrollable>
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <h2 class="">New load</h2>
          <v-spacer></v-spacer>
          <v-btn color="white" icon @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider class="mx-4"></v-divider>
        <br />
        <v-card-text>
          <v-form v-model="formData.form">
            <v-row class="mt-2">
              <v-col cols="10" v-if="formData.rowid_broker == ''">

                <v-text-field
                  outlined
                  v-model="inputSearch"
                  label="Search broker by mc number or name..."
                ></v-text-field>


              </v-col>
              <v-col cols="2">
                <v-btn
                  color="primary"
                  v-if="formData.rowid_broker == ''"
                  @click="searchBroker()"
                  :loading="loadingButton"
                  elevation="0"
                  x-large
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-btn color="error" v-else @click="formData.rowid_broker = ''">
                  <v-icon>mdi-backup-restore</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="formData.rowid_broker == ''">
                <v-list
                  class="pt-0 pb-0"
                  v-if="
                    brokerSearch != '' && brokerSearch != 'broker not found'
                  "
                >
                  <v-list-item
                    class="pt-0 pb-0 mt-1 mb-1"
                    v-for="broker in brokerSearch"
                    :key="broker.rowid"
                    style="background-color: #eee; border-radius: 5px"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <strong>{{ broker.name }}</strong>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span class="pr-3">{{ broker.mc_number }}</span>
                        <v-chip
                          label
                          x-small
                          color="success"
                          v-if="broker.credit_report_status == 1"
                          >Approved</v-chip
                        >
                        <v-chip
                          label
                          x-small
                          color="error"
                          v-if="broker.credit_report_status == 0"
                          >Not approved</v-chip
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        small
                        v-if="broker.credit_report_status == 1"
                        color="success"
                        @click="selectBroker(broker)"
                      >
                        Select
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <div
                  v-if="brokerSearch == 'broker not found'"
                  class="px-2 py-2"
                  style="background-color: #eee; border-radius: 5px"
                >
                  <span>
                    <strong
                      >
We have not found the broker you are looking for, you can register the load by leaving the following data to validate.
                    </strong>
                  </span>
                  <br />
                  <br />
                  <v-form>
                    <v-row>
                      <v-col col="12" md="6">
                        <v-text-field
                          outlined
                          type="number"
                          label="Mc number"
                          class="input"
                          v-model="formBrokerRequest.mc_number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          outlined
                          type="text"
                          label="Name"
                          class="input"
                          v-model="formBrokerRequest.name"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" elevation="0" @click="selectBroker({ rowid: '0', name: formBrokerRequest.name, name_request: formBrokerRequest.name, mc_number_request: formBrokerRequest.mc_number })">Select</v-btn>
                      </v-col>
                    </v-row>
                    
                  </v-form>
                </div>
              </v-col>
              <v-col cols="12" v-if="formData.rowid_broker != ''">
                <strong>Information load</strong>
              </v-col>
              <v-col cols="12" v-if="formData.rowid_broker != ''">
                <v-text-field
                  outlined
                  disabled
                  v-model="formData.broker_name"
                  label="Broker"
                  class="input"
                ></v-text-field>
                <v-text-field
                  outlined
                  type="date"
                  v-model="formData.load_date"
                  label="Date"
                  class="input"
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="formData.load_number"
                  label="Rate/Load Confirmation No. *"
                  class="input"
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="formData.load_amount"
                  label="Rate/Load Confirmation Amount *"
                  class="input"
                ></v-text-field>
                <v-text-field
                  outlined
                  v-model="formData.bol_number"
                  label="Bill of Lading No."
                  class="input"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" v-if="formData.rowid_broker != ''">
                <strong>Deductions</strong>
              </v-col>
              <v-col cols="12" v-if="formData.rowid_broker != ''">
                <v-text-field
                  outlined
                  v-model="formData.lumper"
                  label="Lumper"
                  class="input"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" v-if="formData.rowid_broker != ''">

                <strong>Upload documents</strong>
              </v-col>
              <v-col cols="12" v-if="formData.rowid_broker != ''">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-card outlined class="rounded-lg">
                      <v-card-text>
                        <small>Rate/Load confirmation No.</small> <br />
                        <span>
                          {{ formData.documents[0].nameFile }}
                        </span>
                        <br />
                        <center>
                          <v-icon size="70">mdi-file-pdf-box</v-icon>
                        </center>
                        <br />
                        <br />

                        <label
                          for="choose00"
                          class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                        >
                          Choose document
                        </label>
                        <input
                          type="file"
                          @change="upFileLoadConfirmation"
                          id="choose00"
                          accept=".pdf"
                          style="display: none"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-card outlined class="rounded-lg">
                      <v-card-text>
                        <small>Bill of lading</small> <br />
                        <span>
                          {{ formData.documents[1].nameFile }}
                        </span>
                        <br />
                        <center>
                          <v-icon size="70">mdi-file-pdf-box</v-icon>
                        </center>
                        <br />
                        <br />

                        <label
                          for="choose01"
                          class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                        >
                          Choose document
                        </label>
                        <input
                          type="file"
                          id="choose01"
                          @change="upFileBillOfLading"
                          accept=".pdf"
                          style="display: none"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <!-- <v-col cols="12" md="4">
                    <v-card outlined class="rounded-lg">
                      <v-card-text>
                        <small>Lumper</small> <br />
                        <span>
                          {{ formData.documents[2].nameFile }}
                        </span>
                        <br />
                        <center>
                          <v-icon size="70">mdi-file-pdf-box</v-icon>
                        </center>
                        <br />
                        <br />

                        <label
                          for="choose02"
                          class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                        >
                          Choose document
                        </label>
                        <input
                          type="file"
                          id="choose02"
                          @change="upFileLumper"
                          accept=".pdf"
                          style="display: none"
                        />
                      </v-card-text>
                    </v-card>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            :loading="loadingButton"
            :disabled="loadingButton"
            @click="registerLoad()"
          >
            Send data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  props: ["modal"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    brokerSearch: [],
    inputSearch: "",
    loadingButton: false,
    formData: {
      form: false,
      rowid_broker: "",
      broker_name: "",
      load_number: "",
      load_amount: "",
      lumper: "",
      bol_number: "",
      load_date: "",
      username: "",
      operation: "",
      name_broker_request: "",
      mc_number_request: "",
      rowid_account: "",

      documents: [
        { nameFile: "", base64: null, type: "LOAD_CONFIRMATION_NO" },
        { nameFile: "", base64: null, type: "BILL_OF_LADING_NO" },
        { nameFile: "", base64: null, type: "LUMPER" },
      ],
    },
    formBrokerRequest: {
      name: '',
      mc_number: ''
    }
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    registerLoad: function () {
      this.loadingButton = true;
      const USERSESSION = JSON.parse(localStorage.getItem("USERDATA"));
      const TOKEN = USERSESSION.token;
      const USERNAME = USERSESSION.username;
      const ROWID_ACCOUNT = USERSESSION.rowid_account;

      this.formData.rowid_account = ROWID_ACCOUNT;
      this.formData.username = USERNAME;
      this.formData.operation = this.$route.params.id;

      const BASE_URL = `${this.ip}register-load`;
      let config = {
        headers: {
          Authorization: "Bearer " + TOKEN,
        },
      };
      axios
        .post(BASE_URL, this.formData, config)
        .then((res) => {
          this.loadingButton = false;
          if (res.data.status == true) {
            this.closeModal();
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.loadingButton = false;
          console.log(err);
        });
    },
    searchBroker: function () {
      this.loadingButton = true;
      const BASE_URL = `${this.ip}search-brokers`;
      axios
        .post(BASE_URL, {
          search: this.inputSearch,
        })
        .then((res) => {
          this.loadingButton = false;
          if (res.data.data.length > 0) {
            this.brokerSearch = res.data.data;
          } else {
            this.brokerSearch = "broker not found";
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    selectBroker: function (broker) {
      this.formData.rowid_broker = broker.rowid;
      this.formData.broker_name = broker.name;
      if(broker.mc_number_request){
        this.formData.name_broker_request = broker.name_request;
        this.formData.mc_number_request = broker.mc_number_request;
      }else{
        this.formData.name_broker_request = '';
        this.formData.mc_number_request = '';
      }

      console.log(this.formData, 'formData');
    },
    closeModal() {
      this.$emit("closeModal");
    },

    upFileLoadConfirmation: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formData.documents[0].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formData.documents[0].base64 = e.target.result;
      };

      console.log(this.formData.documents);
    },
    upFileBillOfLading: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formData.documents[1].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formData.documents[1].base64 = e.target.result;
      };

      console.log(this.formData.documents);
    },
    upFileLumper: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formData.documents[2].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formData.documents[2].base64 = e.target.result;
      };

      console.log(this.formData.documents);
    },
  },
};
</script>
<style scoped>
.input {
  margin-bottom: -17px;
}
</style>
