<template>
  <v-row class="py-8 px-8">
    <v-col cols="12">
      <h1 class="primary--text">Brokers request</h1>
      <navigation :nav="itemsNavigation" />
    </v-col>
    <v-col cols="12">
      <v-card class="rounded-lg" elevation="0" color="white">
        <v-card-text>
          <v-row dense>
            <v-spacer></v-spacer>
            <v-col cols="12" md="5">
              <v-text-field rounded filled label="Search request by MC number..."></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
            <v-data-table :headers="headers" :items="request" class="elevation-3" :items-per-page="100">
              <template v-slot:item="{ item }">
              <tr>
                <td class="py-3 my-4">
                  <span> # {{ item.id }} </span>
                </td>
                <td class="">
                  <strong class="primary--text"> 
                    {{ item.broker }} 
                  </strong>
                </td>
                <td class="">
                  <strong class="primary--text">
                    {{ item.broker_name }}
                  </strong>
                </td>
                <td>
                  <span>{{ item.created_at }}</span>
                </td>
                <td class="">
                  <span>
                    {{ item.request_user }}
                  </span>
                </td>
                 <td class="">
                  <span>
                    <v-btn x-small elevation="0" fab color="error" @click="actionModal(true, 2, item)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn x-small elevation="0" fab color="success" class="mx-1" @click="actionModal(true, 1, item)">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                  </span>
                </td>
              </tr> 
              </template>
            </v-data-table>
            <modalaction 
              :dialog="modalAction.modal" 
              :action="modalAction.action" 
              :item="modalAction.item" 
              @closeDialogActions="closeDialog()"
              @updateDataF="getRequest()"
            />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import navigation from "../../components/navigation/breadcrumbs.vue";
import modalaction from '../../components/admin/request-broker/actionRequestBroker.vue'

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    itemsNavigation: [
      {
        text: "Dashboard",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Brokers request",
        disabled: true,
      },
    ],
    modalAction: {
      modal: false,
      action: 0,
      item: []
    },
    request: [],
    headers: [
      {
        text: "id",
        align: "start",
        value: "id",
      },
      {
        text: "Broker MC number",
        value: "broker_mcnumber",
        align: "start",
      },
      {
        text: "Broker name",
        value: "broker_name",
        align: "start",
      },
      {
        text: "Request date",
        value: "created_at",
        align: "start", 
      },
      {
        text: "Request user",
        value: "request_user",
        align: "start", 
      },
      {
        text: "actions",
        align: "start",
      },
    ],
  }),
  components: { navigation, modalaction },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getRequest: function(){
        this.showLoading();
        const BASE_URL = `${this.ip}get-request`;
        axios.get(BASE_URL).then(res => {
            this.hideLoading();
            console.log(res.data.data);
            if(res.data.message == 'Request found'){
                const data = res.data.data;
                let request = [];
                data.forEach(element => {
                    request.push({
                        id: element.rowid,
                        broker_mcnumber: element.broker_mcnumber,
                        broker_name: element.broker_name,
                        request_user: element.request_user,
                        created_at: this.formatDate(element.created_at),
                        broker_usdot_number: '',
                        broker_fp_code: ''
                    });
                });

                this.request = request;
            }else{
              this.request = [];
            }
        }).catch(err => {
           const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
            };
            this.openAlert(optionsAler);
            this.hideLoading();
            console.log(err)
        })
    },
    actionModal: function(val, action, item){
      this.modalAction.modal = val;
      this.modalAction.action = action;
      this.modalAction.item = item;

      console.log('value', val, 'action', action)
    },
    closeDialog:function(){
      this.modalAction.modal = false;
      this.modalAction.action = '';
      this.modalAction.item = [];
    },
    formatDate: function(date){
        const newDate = new Date(date);
        const day = newDate.getDay() > 10 ? newDate.getDay() : `0${newDate.getDay()}`;
        let month = newDate.getMonth() > 10 ? newDate.getMonth() : `0${newDate.getMonth()}`;
        const year = newDate.getFullYear();

        return `${day}-${month}-${year}`;
    }
  },
  mounted() {
    this.getRequest();
  },
};
</script>
