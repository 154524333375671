<template>
  <v-container>
    <v-row justify="center" class="pb-8 pt-8">
      <v-col cols="12" md="3">
        <v-row>
          <v-col cols="12" md="12">
            <!--<h1 class="primary--text">Manage user</h1>-->
            <navigation :nav="itemsNavigation" />
          </v-col>
          <v-col
            cols="12"
            v-if="userData.ind_accept == 0 && userActions == false"
          >
            <v-alert elevation="0" text color="error" dense class="rounded-lg">
              Please complete the business profile and operation setup
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-card
              elevation="0"
              class="rounded-lg"
              v-if="userData.ind_accept == 1"
            >
              <v-card-text>
                <strong style="font-size: 19px" class="primary--text"
                  >Finish</strong
                >
                <p style="font-size: 15px" />
                New account decision after review?
              </v-card-text>
              <v-card-actions>
                <dialogdecline
                  :dialogDecline="dialogDecline"
                  @declineUser="emitEvent"
                  @closeDecline="closeDeclineFun"
                />
                <!-- <v-spacer></v-spacer> -->
                <v-row>
                  <v-col cols="12">
                    <v-btn color="error" block @click="generateContracts()"
                      >
                      <v-icon >mdi-file-pdf-box</v-icon >
                        Preview documents 
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      block
                      elevation="0"
                      :disabled="!userActions"
                      color="ligth"
                      @click="declineAccount()"
                    >
                      <v-icon>mdi-close</v-icon> Decline
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      block
                      :disabled="
                        !userActions ||
                        userData.contract_noa == null ||
                        userData.contract_express == null
                      "
                      elevation="4"
                      color="success"
                      @click="actionsAccount('accept', '')"
                    >
                      <v-icon>mdi-check</v-icon> Accept
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
            <v-card
              elevation="0"
              class="rounded-lg"
              v-if="userData.status == 1 || userData.status == 2"
            >
              <v-card-text>
                <strong style="font-size: 19px" class="primary--text"
                  >Note</strong
                >
                <p style="font-size: 15px">
                  This user registration request has been
                  {{ userData.status == 1 ? "approved." : "declined." }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              elevation="6"
              @click="$router.push('admin-users')"
              block
              large
              dark
              color="primary"
              >Back to list</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="9">
        <v-card>
          <!--<v-toolbar color="primary" dark flat>
            <v-toolbar-title>Account information details</v-toolbar-title>
            <template v-slot:extension>-->
          <v-tabs
            v-model="tab"
            align-with-title
            color="white"
            background-color="primary"
            flat
            dark
          >
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <!--</template>
          </v-toolbar>-->

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card flat color="#f6f6f6">
                <v-card-text>
                  <v-card class="rounded-lg" elevation="0">
                    <v-card-title class="mx-0 my-0 px-0 py-0">
                      <headercard
                        colorIcon="primary"
                        colorAvatarIcon="primary lighten-2"
                        icon="mdi-account"
                        classTextTitle="primary--text"
                        title="User data"
                        subtitle=""
                      />
                    </v-card-title>
                    <!-- <v-divider class="mx-4"></v-divider> -->
                    <v-card-text v-if="userData != ''">
                      <userdata :user="userData" />
                    </v-card-text>
                  </v-card>
                  <br />

                  <v-card class="rounded-lg" elevation="0">
                    <v-card-title class="mx-0 my-0 px-0 py-0">
                      <headercard
                        colorIcon="primary"
                        colorAvatarIcon="primary lighten-2"
                        icon="mdi-clipboard-text"
                        classTextTitle="primary--text"
                        title="Business information"
                        subtitle=""
                      />
                    </v-card-title>
                    <!-- <v-divider class="mx-4"></v-divider> -->
                    <v-card-text>
                      <businessdata
                        :businessData="businessData"
                        :userData="userData"
                        v-if="businessData != ''"
                      />
                    </v-card-text>
                  </v-card>
                  <br />
                  <v-card class="rounded-lg" elevation="0">
                    <v-card-title class="mx-0 my-0 px-0 py-0">
                      <headercard
                        colorIcon="primary"
                        colorAvatarIcon="primary lighten-2"
                        icon="mdi-phone"
                        classTextTitle="primary--text"
                        title="Main contact"
                        subtitle=""
                      />
                    </v-card-title>
                    <!-- <v-divider class="mx-4"></v-divider> -->
                    <v-card-text>
                      <businesscontact :businessContact="businessContact" />
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat color="#f6f6f6">
                <v-card-text>
                  <operationinformation
                    :businessData="businessData"
                    :formData="operationData"
                    :actionUser="userActions"
                    @chageActions="updateActions()"
                    v-if="businessData != ''"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import headercard from "../../components/headers/headerIcon.vue";
import dialogdecline from "../../components/admin/accept-user/modals/declineUser.vue";
import navigation from "../../components/navigation/breadcrumbs.vue";
import userdata from "../../components/admin/accept-user/userDataForm.vue";
import businessdata from "../../components/admin/accept-user/businessDataForm.vue";
import businesscontact from "../../components/admin/accept-user/businessContactForm.vue";
import operationinformation from "../../components/admin/accept-user/operationInformation.vue";
export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    userData: [],
    businessData: [],
    businessContact: [],
    userActions: false,
    dialogDecline: false,
    itemsNavigation: [
      {
        text: "List users",
        disabled: false,
        href: "/#/admin-users",
      },
      {
        text: "New User",
        disabled: true,
        href: "breadcrumbs_link_1",
      },
    ],

    operationData: {
      form: false,
      account_type: "Freight Factoring",
      client_code: "",
      client_since: "",
      advance_rate: "",
      invoice_terms: "",
      factoring_fee: "",
      factoring_fee_period: "",
      collection_fee: "",
      collection_fee_period: "",
      po_advance: "",
      po_advance_fee: 35,
      ach_fee: "",
      etf_fee: "",
      ucc_fee: "",
      setup_fee: "",
      as_processing_fee: "",
      receivable_fin_facility: "",
      reserve: "",
      late_payment_charge: "",
      late_payment_days: "",
      invoice_terms: "",
      relationship_period: "",
      re_docymentation_fee: "",
      rowid_account: "",
    },

    tab: null,
    items: ["Business Profile", "Account Table Setup"],
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getDataUser: function () {
      this.showLoading();
      const userId = this.$route.params.id;
      const BASE_URL = `${this.ip}get-data-user-to-accept-account&user=${userId}`;
      axios
        .get(BASE_URL)
        .then((res) => {
          this.hideLoading();
          if (res.data.message == "user found") {
            const data = res.data;
            this.userData = data.dataUser;
            this.businessData = data.dataBusiness;
            this.businessContact = data.contactBusiness;
            this.operationData = data.operationData;

            if (data.operationData.client_code != "") {
              this.userActions = true;
            }
          } else {
            const optionsAler = {
              message: "User not found",
              submessage:
                "We have not found information of a user with this id",
              action: "redirect",
              path: "/admin-users",
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    actionsAccount: function (action, note) {
      let username = JSON.parse(localStorage.getItem("USERDATA"));
      username = username.username;
      this.showLoading();
      const userAccount = this.$route.params.id;
      const BASE_URL = `${this.ip}action-account-client`;
      axios
        .post(BASE_URL, { action, userAccount, note, username })
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            const optionsAler = {
              message: res.data.message,
              submessage: "",
              action: "redirect",
              path: "/admin-users",
            };
            this.openAlert(optionsAler);
            this.getDataUser();
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    generateContracts: function () {
      const username = this.userData.username;
      const BASE_URL = `${this.ip}generate-contracts`;
      axios
        .post(BASE_URL, { username: username })
        .then((res) => {
          this.getDataUser();
          if (res.data.status == true) {
            const optionsAler = {
              message: res.data.message,
              submessage: "",
              action: "close",
              path: "/",
            };
            this.openAlert(optionsAler);
            window.open(res.data.link, "_blank");
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    declineAccount: function () {
      this.dialogDecline = true;
    },
    emitEvent: function (value) {
      this.dialogDecline = false;
      this.actionsAccount("decline", value);
    },
    closeDeclineFun: function () {
      this.dialogDecline = false;
    },
    updateActions: function () {
      this.userActions = true;
    },
  },
  components: {
    navigation,
    userdata,
    businessdata,
    headercard,
    businesscontact,
    dialogdecline,
    operationinformation,
  },
  mounted() {
    this.getDataUser();
  },
};
</script>
