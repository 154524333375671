<template>
  <div>
    <v-app-bar elevation="0" app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">

        <v-btn
          color="primary"
          dark
          text
          v-bind="attrs"
          v-on="on"
        >
      <!-- <v-avatar size="30">
        <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John" />
      </v-avatar> -->
        <span class="white--text">{{ userInfo.firstName + ' ' + userInfo.lastName }}</span> &nbsp; &nbsp;
        <span class="white--text">{{username}}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="push('/config-account')">
          <v-list-item-title> <v-icon>mdi-account</v-icon> Update password</v-list-item-title>
        </v-list-item>
        <v-list-item @click="logout()">
          <v-list-item-title> <v-icon>mdi-logout</v-icon> Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>



    </v-app-bar>

    <!-- drawer -->
    <v-navigation-drawer :temporary="false" app v-model="drawer" color="#f6f6f6">
      <v-list-item class="mt-0 mb-0 pt-5 pb-3">
        <v-list-item-content class="mt-0 mb-0 pt-0 pb-0">
          <v-list-item-title class="mt-0 mb-0 pt-0 pb-0">
            <center>
              <img
                width="200"
                alt="Factor Plus Logo"
                style="margin: 0 !important; padding: 0 !important"
                src="../assets/Logos/logo_fp_h_415.png"
              />
              <br />
              <span style="font-size: 10px;" class="primary--text">{{ this.version }}</span> 
            </center>
          </v-list-item-title>
          <v-list-item-subtitle>
            <center>
              <span class="primary--text">{{ userInfo.firstName + ' ' + userInfo.lastName }}</span>
            </center>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav>
        <v-list-group v-for="item in items" :key="item.name" :value="true">
          <template v-slot:activator>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </template>

          <v-list-item
            v-for="menu in item.items"
            :key="menu.name"
            link
            :to="menu.href"
            class="ml-4"
          >
            <v-list-item-icon class="mr-2">
              <v-icon v-text="menu.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="menu.title"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data: () => ({
    username: "",
    userInfo: [],
    drawer: false,
    items: [],
    version: process.env.VUE_APP_VERSION,
  }),
  methods: {
    logout: function () {
      localStorage.removeItem("USERDATA");
      localStorage.removeItem("USERMENU");
      this.$router.push("/admin");
    },
    validateLoginInformation() {
      if (
        localStorage.getItem("USERDATA") &&
        localStorage.getItem("USERMENU")
      ) {
        let menu = localStorage.getItem("USERMENU");
        let userData = JSON.parse(localStorage.getItem("USERDATA"));
        menu = JSON.parse(menu);
        this.items = menu;
        this.username = userData.username;
        this.userInfo = userData;
      } else {
        this.logout();
      }
    },
    push:function(route){
      this.$router.push(route)
    }
  },
  mounted() {
    this.validateLoginInformation();
  },
};
</script>
<style lang="css" scoped>
.link {
  text-transform: none !important;
  text-decoration: none;
  color: white !important;
  font-weight: 400;
}

.v-item--active {
  color: #2a3b4d !important;
  font-weight: 800 !important;
}
</style>
