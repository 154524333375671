<template>
  <v-row
    class="py-0 px-0 my-0 mx-0 black--text container-login"
    style="background-color: #0072c3"
  >
    <v-col
      cols="12"
      md="8"
      class="d-none d-md-block"
      style="position: relative"
    >
      <div class="message white--text">
        <v-row>
          <v-col cols="12" md="12">
            <v-card  elevation="0" class="rounded-lg">
              <v-card-text>
                <center>
                  <img src="../../assets/Logos/logo_fb_h_500.png" width="300"  />
                </center>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> 
         <br>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
        when an unknown printer took a galley of type and scrambled it to make a type specimen book.       </div>
    </v-col>
    <v-col cols="12" md="4" style="background-color: white" class="black--text">
      <v-row  class="py-5 px-5" dense>
        <v-col cols="12">
          <center>
          <img src="../../assets/Logos/logo_fp_h_415.png" width="300"/>
          </center>
          <br />
          <h2>Sign Up</h2>
          <p class="grey--text">
            Already have an account? <a href="/">Sign in</a>
          </p>
        </v-col>
        <v-col cols="12"> 
          <v-form v-model="form.form">
            <v-text-field
              outlined
              label="Enter your email"
              name="email"
              :rules="[rules.req, rules.email]"
              v-model="form.email"
            ></v-text-field>
            <v-text-field
              outlined
              v-model="form.phone"
              type="number"
              :rules="[rules.req]"
              name="number_phone"
              label="Enter your phone number"
              maxlength="10"
            ></v-text-field>

            <v-btn
              block
              :disabled="!form.form"
              x-large
              elevation="0"
              @click="generateLink()"
              color="primary"
              >Sing Up</v-btn
            >
          </v-form>
        </v-col>

        <v-col cols="12">
          <v-alert
            text
            color="primary"
            class="white--text rounded-lg mt-8 mb-8 pt-5 pb-5"
            border="left"
            elevation="0"
            icon="mdi-gmail"
            prominent
          >
            Check your email to complete your account registration. 
          </v-alert>
        </v-col>
      </v-row>
    </v-col>

    <v-snackbar v-model="snackbar.value" :multi-line="snackbar.multiLine">
      <h2>
        {{ snackbar.field }}
      </h2>
      <br />
      <p>
        {{ snackbar.text }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    snackbar: {
      value: false,
      text: "",
      field: "",
      multiline: true,
    },
    form: {
      form: false,
      email: "",
      phone: "",
    },

    // validations
    rules: {
      req: (value) => !!value || "required.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      max: (v) => (v && v.length <= 30) || "Max 30 characters",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalid",
      pass: (v) =>
        (v && v == this.dataForm.password) || "Passwords do not match",
      // emailMatch: () => ('The email and password you entered don\'t match'),
    },
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert", "closeAlert"]),
    generateLink: function () {
      this.showLoading();
      const BASE_URL = `${this.ip}link-to-register`;
      axios.post(BASE_URL, this.form).then((res) => {
        this.hideLoading();
        if (res.data.message == "Error in validation") {
          if (res.data.error["email"]) {
            this.snackbar.value = true;
            this.snackbar.text = res.data.error["email"][0];
            this.snackbar.field = "Email";
          }

          if (res.data.error["phone"]) {
            this.snackbar.value = true;
            this.snackbar.text = res.data.error["phone"][0];
            this.snackbar.field = "Phone";
          }
        }

        if (res.data.message == "Email send") {
          const optionsAler = {
            message: "Notification",
            submessage:
              "Please check your email to complete your account registration",
            action: "redirect",
            path: "/",
          };
          this.openAlert(optionsAler);
        }
      }).catch(err => {
        const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
        };
        this.openAlert(optionsAler);
        this.hideLoading();
        console.log(err);
      });
    },
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-login {
  height: 100vh;
}
</style>
