import Vue from 'vue'
import VueRouter from 'vue-router'


import Login from '../views/Generals-views/Login.vue'
import ConfigAccount from '../views/Generals-views/ConfigAccount.vue'
import Register from '../views/Generals-views/Register.vue'
import CompleteRegistration from '../views/Generals-views/CompleteRegistration.vue'
import CompleteRegistrationAdministrator from '../views/Generals-views/CompleteRegistrationAdministrator.vue';
import AdminUsers from '../views/Admin/Users.vue'
import DetailUser from '../views/Admin/DetailUser.vue'
import RecoverPassword from '../views/Generals-views/RecoverPassword'

import ConsultBroker from '../views/Client/ConsultBroker.vue'
import LogBroker from '../views/Admin/LogBrokers.vue'
import BrokersRequest from '../views/Admin/RequestBrokers.vue'
import AdminBrokers from '../views/Admin/AdminBrokers.vue'
// operations
import Operations from '../views/Client/Operations.vue';
import DetailOperation from '../views/Client/DetailOperation.vue';

// errors pages

import NotRegisterUser from '../views/Pages-messages/NotUserCompleteForm.vue'
import RegistrationStarted from '../views/Pages-messages/RegistrationStarted.vue'

import Contract from '../views/Contract/ReviewContract.vue'

import HeaderBasic from '../components/headers/headerBasic.vue';
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/admin',
        name: 'Login',
        components: {
            // header: Header,
            content: Login,
            // footer: Footer
        }
    },
    {
        path: '/config-account',
        name: 'Config account',
        components: {
            header: Header,
            content: ConfigAccount,
            // footer: Footer
        }
    },
    {
        path: '/register',
        name: 'Register',
        components: {
            // header: Header,
            content: Register,
            // footer: Footer
        }
    },
    {
        path: '/finish-registration&token=:token',
        name: 'Complete registration',
        components: {
            content: CompleteRegistration
        }
    },
    {
        path: '/finish-registration-administrator&token=:token',
        name: 'Complete registration',
        components: {
            content: CompleteRegistrationAdministrator
        }
    },
    {
        path: '/admin-users',
        name: 'Admin users',
        components: {
            header: Header,
            content: AdminUsers,
            footer: Footer
        }
    },
    {
        path: '/',
        name: 'Search brokers',
        components: {
            // header: Header,
            content: ConsultBroker,
            footer: Footer
        }
    },
    {
        path: '/search-brokers',
        name: 'Search brokers not login',
        components: {
            // header: Header,
            content: ConsultBroker,
            footer: Footer
        }
    },
    {
        path: '/log-brokers',
        name: 'Log brokers',
        components: {
            header: Header,
            content: LogBroker,
            footer: Footer
        }
    },
    {
        path: '/admin-brokers',
        name: 'Admin brokers',
        components: {
            header: Header,
            content: AdminBrokers,
            footer: Footer
        }
    },
    {
        path: '/brokers-request',
        name: 'Brokers request ',
        components: {
            header: Header,
            content: BrokersRequest,
            footer: Footer
        }
    },
    {
        path: '/operations',
        name: 'Operations',
        components: {
            header: Header,
            content: Operations,
            footer: Footer
        }
    },
    {
        path: '/detail-operation/:id',
        name: 'Operations',
        components: {
            header: Header,
            content: DetailOperation,
            footer: Footer
        }
    },
    {
        path: '/not-register-user',
        name: 'Not register user',
        components: {
            content: NotRegisterUser,
        }
    },
    {
        path: '/registration-started',
        name: 'Registration started',
        components: {
            header: HeaderBasic,
            content: RegistrationStarted,
        }
    },
    {
        path: '/detail-user&id=:id',
        name: 'Detail user',
        components: {
            content: DetailUser,
        }
    },
    {
        path: '/recover-password&token=/:token/&verified=/:verified',
        name: 'Recover password',
        components: {
            content: RecoverPassword,
            // footer: AppFooter
        }
    },
    {
        path: '/review-contract&token=:idUser/:readonly?',
        name: 'Review contract',
        components: {
            content: Contract
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router