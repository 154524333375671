<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <br />
        <br />
        <br />
        <v-card elevation="0" class="rounded-lg">
          <v-card-title>
            <h4 class="primary--text">
              Complete your registration as an administrator
            </h4>
          </v-card-title>
          <v-card-subtitle>
            <span class="grey--text"
              >Complete the necessary information to activate your account</span
            >
          </v-card-subtitle>
          <v-card-text>
            <v-form v-model="dataForm.form">
              <v-row dense>
                <v-col cols="12" md="6">
                  <v-text-field label="Firstname" v-model="dataForm.firstName" name="firstName" :rules="[rules.required]" id="firstName" outlined></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Lastname" v-model="dataForm.lastName" name="lastName" :rules="[rules.required]" id="lastName" outlined></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Username"
                    v-model="dataForm.username"
                    disabled
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field label="Phone" v-model="dataForm.phone" disabled outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <strong class="primary--text"> Create your password </strong>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataForm.password"
                    :append-icon="
                      dataForm.typePassword ? 'mdi-eye' : 'mdi-eye-off'
                    "
                    :rules="[rules.required, rules.min]"
                    :type="dataForm.typePassword ? 'text' : 'password'"
                    name="input-password"
                    label="Password"
                    outlined
                    @click:append="
                      dataForm.typePassword = !dataForm.typePassword
                    "
                  ></v-text-field>
                  <password
                    v-model="dataForm.password"
                    :toggle="true"
                    @score="showScore"
                    @feedback="showFeedback"
                    :strength-meter-only="true"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="dataForm.password_verify"
                    :append-icon="
                      dataForm.typePasswordVerify
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    :rules="[rules.required, rules.min]"
                    :type="
                      dataForm.typePasswordVerify ? 'text' : 'password'
                    "
                    name="input-password"
                    label="Verify Password"
                    outlined
                    @click:append="
                      dataForm.typePasswordVerify =
                        !dataForm.typePasswordVerify
                    "
                  ></v-text-field>
                  <password
                    v-model="dataForm.password_verify"
                    :toggle="true"
                    @score="showScore"
                    @feedback="showFeedback"
                    :strength-meter-only="true"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              large
              block
              color="primary"
              elevation="0"
              :disabled="
                !dataForm.form ||
                dataForm.scorePassword < 3 ||
                dataForm.password != dataForm.password_verify
              "
              @click="completeAccount()"
            >
              Complete account
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import Password from "vue-password-strength-meter";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    dataForm : {
        form: false,
        id: '',
        lastName: '',
        firstName: '',
        phone: '',
        username: '',
        password: '',
        password_verify: '',
        typePassword: false,
        typePasswordVerify: false,
        scorePassword: 0
    },
    rules: {
      req: (value) => !!value || "required.",
      min: (v) => v.length >= 8 || "Min 8 caracteres",
      max: (v) => (v && v.length <= 30) || "Max 30 characters",
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalid",
    },
  }),
  components: { Password },
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    verifiedUser() {
      this.showLoading();
      const token = this.$route.params.token;
      const BASE_URL = `${this.ip}find-admin-complete-register&token=${token}`;
      axios
        .get(BASE_URL)
        .then((res) => {
          this.hideLoading();
          if(res.data.message == 'user found'){
            this.dataForm.username = res.data.data.username;
            this.dataForm.phone = res.data.data.phone;
            this.dataForm.id = res.data.data.id;
          }else{
            const optionsAler = {
                message: "User not found",
                action: "close",
                path: "/",
            };
            this.openAlert(optionsAler);
          }
        })
        .catch((err) => {
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
        });
    },
    completeAccount:function(){
        this.showLoading();
        const BASE_URL = `${this.ip}complete-account-admin`;
        axios.post(BASE_URL, this.dataForm).then(res => {
            this.hideLoading();
            const optionsAler = {
                message: "Account complete",
                action: "redirect",
                path: "/",
            };
            this.openAlert(optionsAler);
        }).catch(err => {
            const optionsAler = {
                message: "Error in server, please contact the administrator.",
                action: "close",
                path: "/",
            };
            this.openAlert(optionsAler);
            this.hideLoading();
            console.log(err);
        });
    },
    showFeedback({ suggestions, warning }) {
      // console.log("🙏", suggestions);
      // console.log("⚠", warning);
    },
    showScore(score) {
      this.dataForm.scorePassword = score;
    },
  },
  mounted() {
    this.verifiedUser();
  },
};
</script>
