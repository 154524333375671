<template>
  <v-dialog v-model="$store.state.recoverPassword" width="450px" persistent>
    <v-card v-if="$store.state.recoverPassword" width="450px">
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <strong class="title_card primary--text"
              >Recover your password</strong
            >
            <br />
            <span
              >We'll email you instructions with a link <strong>link</strong> to
              recover your password</span
            >
          </v-col>
          <v-col cols="12">
            <v-text-field outlined v-model="email" label="email"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn elevation="0" @click="$store.state.recoverPassword = false"
          >Cancel</v-btn
        >
        <v-btn elevation="6" @click="emailRecover()" color="primary"
          >Recover password</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    email: "",
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    emailRecover: function () {
      if (this.email != "") {
        this.showLoading();
        const BASE_URL = `${this.ip}recover-password`;
        axios
          .post(BASE_URL, { email: this.email })
          .then((res) => {
            this.hideLoading();
            if (res.data.status == true) {
              const optionsAler = {
                message: "The password recovery link has been sent",
                submessage:
                  "Check your inbox in the mail, do not forget to check the spam folder",
                action: "redirect",
                path: `/`,
              };

              this.openAlert(optionsAler);
            }
          })
          .catch((err) => {
            const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
            };
            this.openAlert(optionsAler);
            this.hideLoading();
            console.log(err);
          });
      } else {
        const optionsAler = {
          message: "Email is required",

          action: "close",
          path: `/`,
        };

        this.openAlert(optionsAler);
        // alert("Email is required");
      }
    },
  },
};
</script>
<style scoped>
.title_card {
  font-size: 19px !important;
}
</style>
