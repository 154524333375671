<template>
  <v-row class="py-8 px-8">
    <v-col cols="12">
      <v-btn text color="primary" @click="push('/operations')"> <v-icon>mdi-chevron-left</v-icon> Back to list advances</v-btn>
    </v-col>
    <v-col cols="12">
      <h1 class="primary--text">Advance detail > <strong class="grey--text">#{{idOperation}}</strong></h1>
    </v-col>
    <v-col cols="12">
      <v-card elevation="0" class="rounded-lg">
       
        <v-card-text>
          <v-row>
            <v-col cols="10">
              
              <modaladdload :modal="modal" @closeModal="closeModalAdd()" v-if="modal == true" />
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" @click="openModal()">
                <strong>Add load</strong>
              </v-btn>
              
            </v-col>
            <v-col cols="2">
              <v-spacer></v-spacer>
              <v-btn color="success" :disabled="loads == ''" elevation="0">Confirm Advance</v-btn>
            </v-col>
            <v-col cols="12">
              <h2 class="primary--text mt-7">List loads</h2>
            </v-col>
            <newaddition  :dialog="dialogNewAddition" @closeDialog="closeDialogs()" />
            <newdeduction :dialog="dialogNewDeduction" @closeDialog="closeDialogs()" />
            <v-col cols="12">
              <v-data-table :items="loads" :headers="headers" :items-per-page="100">
                <template v-slot:item="{ item }">
                  <tr>
                    <!-- <td>
                      <span>#{{ item.rowid }}</span>
                    </td> -->
                    <td>
                      <span> <v-btn color="error" @click="openNewDeduction()" small elevation="0"> - </v-btn> <v-btn color="success" @click="openNewAddition()" small elevation="5"> + </v-btn> </span>
                    </td>
                    <td>
                      <span>{{ item.name }}</span>
                    </td>
                    <td>
                      <span>{{ item.load_number }}</span>
                    </td>
                    <td>
                      <span>${{ item.load_amount }}</span>
                    </td>
                    <td>
                      <span>{{ item.bol_number }}</span>
                    </td>
                    
                    <td>
                      
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapMutations } from "vuex";
import modaladdload from "../../components/generals/operations/addLoadOperation.vue";
import newaddition from "../../components/admin/operations/newAdditions.vue";
import newdeduction from "../../components/admin/operations/newDeduction.vue"

export default {
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    modal: false,
    idOperation: '',
    loads: [],
    headers: [
      // {
      //   text: "Id",
      //   align: "start",
      //   value: "rowid",
      // },
      { text: "Deductions/Additions", value: "" },
      { text: "Broker", value: "name" },
      { text: "Rate/Load confirmation no", value: "load_number" },
      { text: "Rate/Load confirmation amount", value: "load_amount" },
      { text: "Bill of lading no.", value: "bol_number" },
      { text: "", value: "" },
    ],
    dialogNewAddition: false,
    dialogNewDeduction: false
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    getLoadsOperation: function () {
      this.showLoading();
      const idOperation = this.$route.params.id;
      const USERSESSION = JSON.parse(localStorage.getItem("USERDATA"));
      const TOKEN = USERSESSION.token;

      const BASE_URL = `${this.ip}load-by-operation&operation=${idOperation}`;
      let config = {
        headers: {
          Authorization: "Bearer " + TOKEN,
        },
      };
      axios.get(BASE_URL, config).then((res) => {
        this.hideLoading();
        this.loads = res.data.data;
      }).catch(err => {
         const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
          };
          this.openAlert(optionsAler);
          this.hideLoading();
          console.log(err);
      });
    },
    openModal: function () {
      this.modal = true;
    },
    closeModalAdd: function () {
      this.getLoadsOperation();
      this.modal = false;
    },
    push(route){
      this.$router.push(route);
    },
    openNewAddition:function(){
      this.dialogNewAddition = true;
    },
    openNewDeduction:function(){
      this.dialogNewDeduction = true;
    },
    closeDialogs:function(){
      this.dialogNewAddition = false;
      this.dialogNewDeduction = false;
    }
  },
  components: {
    modaladdload, newaddition, newdeduction
  },
  mounted() {
    this.idOperation = this.$route.params.id;
    this.getLoadsOperation();
  },
};
</script>
