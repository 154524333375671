<template>
  <div>
    <v-card-text>
      <v-row class="px-7 py-2">
        <v-col cols="12" md="3">
          <center>
            <v-card outlined class="rounded-lg">
              <v-card-text>
                <small>US Dot/MC Number *</small> <br />
                <span>
                  {{ formDocuments[0].nameFile }}
                </span>
                <br />
                <v-icon
                  :color="formDocuments[0].nameFile == '' ? 'grey' : 'red'"
                  size="70"
                  >mdi-file-pdf-box</v-icon
                >
                <br />
                <br />

                <label
                  for="choose00"
                  class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                >
                  Choose document
                </label>
                <input
                  type="file"
                  @change="upFileMcNumber"
                  id="choose00"
                  accept=".pdf"
                  style="display: none"
                />
              </v-card-text>
            </v-card>
          </center>
        </v-col>
        <v-col cols="12" md="3">
          <center>
            <v-card outlined class="rounded-lg">
              <v-card-text>
                <small>Voided check *</small> <br />
                <span>
                  {{ formDocuments[1].nameFile }}
                </span>
                <br />
                <v-icon
                  :color="formDocuments[1].nameFile == '' ? 'grey' : 'red'"
                  size="70"
                  >mdi-file-pdf-box</v-icon
                >
                <br />
                <br />

                <label
                  for="choose01"
                  class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                >
                  Choose document
                </label>
                <input
                  type="file"
                  @change="upFileVoided"
                  id="choose01"
                  accept=".pdf"
                  style="display: none"
                />
              </v-card-text>
            </v-card>
          </center>
        </v-col>
        <v-col cols="12" md="3">
          <center>
            <v-card outlined class="rounded-lg">
              <v-card-text>
                <small>Certificate Of Liability *</small> <br />
                <span>
                  {{ formDocuments[2].nameFile }}
                </span>
                <br />
                <v-icon
                  :color="formDocuments[2].nameFile == '' ? 'grey' : 'red'"
                  size="70"
                  >mdi-file-pdf-box</v-icon
                >
                <br />
                <br />

                <label
                  for="choose02"
                  class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                >
                  Choose document
                </label>
                <input
                  type="file"
                  @change="upFileCerificate"
                  id="choose02"
                  accept=".pdf"
                  style="display: none"
                />
              </v-card-text>
            </v-card>
          </center>
        </v-col>
        <v-col cols="12" md="3">
          <center>
            <v-card outlined class="rounded-lg">
              <v-card-text>
                <small>Driver licence *</small> <br />
                <span>
                  {{ formDocuments[3].nameFile }}
                </span>
                <br />
                <v-icon
                  :color="formDocuments[3].nameFile == '' ? 'grey' : 'red'"
                  size="70"
                  >mdi-file-pdf-box</v-icon
                >
                <br />
                <br />

                <label
                  for="choose03"
                  class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                >
                  Choose document
                </label>
                <input
                  type="file"
                  @change="upFileLicence"
                  id="choose03"
                  accept=".pdf"
                  style="display: none"
                />
              </v-card-text>
            </v-card>
          </center>
        </v-col>
        <v-col cols="12" md="3" v-if="countOwners > 1">
          <center>
            <v-card outlined class="rounded-lg">
              <v-card-text>
                <small>Driver license owner two</small> <br />
                <span>
                  {{ formDocuments[4].nameFile }}
                </span>
                <br />
                <v-icon
                  :color="formDocuments[4].nameFile == '' ? 'grey' : 'red'"
                  size="70"
                  >mdi-file-pdf-box</v-icon
                >
                <br />
                <br />

                <label
                  for="choose04"
                  class="v-btn v-btn--block theme--light elevation-0 v-size--small primary"
                >
                  Choose document
                </label>
                <input
                  type="file"
                  @change="upFileLicenceTwo"
                  id="choose04"
                  accept=".pdf"
                  style="display: none"
                />
              </v-card-text>
            </v-card>
          </center>
        </v-col>
      </v-row>
      <v-row class="px-7 py-2">
        <v-col cols="12" md="6">
          <strong class="grey--text">Owner signature *</strong>
          <v-card outlined>
            <VueSignaturePad ref="signaturePad" />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn elevation="0" @click="undoOne()">Undo</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" v-show="countOwners > 1">
          <strong class="grey--text">Signature owner two</strong>
          <v-card outlined>
            <VueSignaturePad ref="signaturePadTwo" />
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn elevation="0" @click="undoTwo()">Undo</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-alert color="primary" class="white--text">
            <input type="checkbox" v-model="userAccept" />
            By signing below I/We certify The information supplied in this
            Application and all forms and documents submitted to Factor Plus
            LLC. in connection herewith are true and correct. I/we hereby
            authorize <b>Factor Plus LLC</b> its affiliates, assigns, agents, bank or
            financial institutions or subsidiaries to verify the accuracy of the
            statements made in this application and to determine our
            creditworthiness by checking our credit reports as <b>Factor Plus LLC</b>
            deems necessary. I/we grant Plus LLC Inc. the right to procure any
            and all credit reports pertaining to any part of this application.
            Further, your signature authorizes Plus LLC. to send information
            whether commerical, business, or otherwise via facsimile or
            electronic transmission to the facsimile number or e-mail address
            I/we have provided herein.
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        elevation="6"
        @click="upLoadFiles()"
        color="primary"
        :disabled="
          formDocuments[0].nameFile == '' ||
          formDocuments[1].nameFile == '' ||
          formDocuments[2].nameFile == '' ||
          formDocuments[3].nameFile == '' ||
          userAccept == false
        "
        large
        >Confirm documents</v-btn
      >
    </v-card-actions>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import VueSignaturePad from "vue-signature-pad";
import { mapMutations } from "vuex";

// firma
Vue.use(VueSignaturePad);

export default {
  props: ["userId"],
  data: () => ({
    ip: process.env.VUE_APP_API_URL,
    userAccept: false,
    countOwners: 2,
    formDocuments: [
      { nameFile: "", base64: null, type: "MC_NUMBER" },
      { nameFile: "", base64: null, type: "VOIDED_CHECK" },
      { nameFile: "", base64: null, type: "CERTIFICATE_LIABILITY" },
      { nameFile: "", base64: null, type: "DRIVER_LICENSE" },
      { nameFile: "", base64: null, type: "DRIVER_LICENSE" },
      { nameFile: "", base64: null, type: "OWNER_SIGNATURE" },
      { nameFile: "", base64: null, type: "OWNER_SIGNATURE" },
    ],
  }),
  methods: {
    ...mapMutations(["showLoading", "hideLoading", "openAlert"]),
    findOwners: function () {
      this.showLoading();
      const BASE_URL = `${this.ip}find-owners-count&userId=${this.userId}`;
      axios
        .get(BASE_URL)
        .then((res) => {
          this.hideLoading();
          if (res.data.status == true) {
            this.countOwners = res.data.data;
          }
        })
        .catch((err) => {
          this.hideLoading();
          const optionsAler = {
            message: "Error in server, please contact the administrator.",
            action: "close",
            path: "/",
          };
          this.openAlert(optionsAler);
          console.log(err);
        });
    },
    upLoadFiles: async function () {
      this.showLoading();

      const signatureOwnerOne = this.saveSignatureOne();
      const signatureOwnerTwo = this.saveSignatureTwo();

      if (signatureOwnerOne.empty == true || signatureOwnerTwo.empty == true) {
        const optionsAler = {
          message: "Owner signature is required",
          action: "close",
          path: `/`,
        };

        this.openAlert(optionsAler);
        this.hideLoading();
        return;
      } else {
        this.formDocuments[5].base64 = signatureOwnerOne.data;
        this.formDocuments[5].nameFile = "owner-signature.png";

        if (signatureOwnerTwo.empty == false && signatureOwnerTwo.data != "") {
          this.formDocuments[6].base64 = signatureOwnerTwo.data;
          this.formDocuments[6].nameFile = "owner-two-signature.png";
        }

        const BASE_URL = `${this.ip}upload-files`;
        axios
          .post(BASE_URL, {
            documents: this.formDocuments,
            user: this.userId,
          })
          .then((res) => {
            this.hideLoading();
            if (res.data.status == true) {
              const optionsAler = {
                message: "Documents uploaded",
                action: "close",
                path: `/`,
              };

              this.openAlert(optionsAler);
              this.$emit("nextStep");
            }
          })
          .catch((err) => {
            const optionsAler = {
              message: "Error in server, please contact the administrator.",
              action: "close",
              path: "/",
            };
            this.openAlert(optionsAler);
            console.log(err);
          });
      }
    },
    upFileLicence: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formDocuments[3].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formDocuments[3].base64 = e.target.result;
      };
    },

    upFileLicenceTwo: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formDocuments[4].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formDocuments[4].base64 = e.target.result;
      };
    },

    upFileMcNumber: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formDocuments[0].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formDocuments[0].base64 = e.target.result;
      };
    },

    upFileVoided: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formDocuments[1].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formDocuments[1].base64 = e.target.result;
      };
    },
    upFileCerificate: function (e) {
      let reader = new FileReader();
      const document = e.target.files[0];
      this.formDocuments[2].nameFile = e.target.files[0].name;
      reader.readAsDataURL(document);
      reader.onload = (e) => {
        this.formDocuments[2].base64 = e.target.result;
      };
    },
    undoOne() {
      this.$refs.signaturePad.undoSignature();
    },
    undoTwo() {
      this.$refs.signaturePadTwo.undoSignature();
    },
    saveSignatureOne() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      return {
        empty: isEmpty,
        data: data,
      };
    },
    saveSignatureTwo() {
      if (this.countOwners > 1) {
        const { isEmpty, data } = this.$refs.signaturePadTwo.saveSignature();
        return {
          empty: isEmpty,
          data: data,
        };
      } else {
        return {
          empty: false,
          data: "",
        };
      }
    },
  },
  mounted() {
    this.findOwners();
  },
};
</script>
